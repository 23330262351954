import React from "react";
import ReactDom from "react-dom";
import style from "./SearchHeader.module.css";
// import SearchModalBackdrop from "./SearchModalBackdrop";
const SearchModalWrapper = ({ onClose, customStyleBackdrop }) => {
  return (
    <div
      className={style.search_backdrop}
      style={{ ...customStyleBackdrop }}
      onClick={onClose}
    />
  );
};

export default SearchModalWrapper;
