import React from "react";
import style from "./TransportMain.module.css";
import TransportSidebar from "../TransportSidebar/TransportSidebar";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useOneInstituteDashboard } from "../../hooks/dashboard/dashboard-api";
import { useState } from "react";
import { useTransportDetail } from "../../hooks/member_tab/transport-api";
import CommonTab from "../../Sidebar/CommonTab";
import { useTranslation } from "react-i18next";
import Vehicles from "./Vehicles/Vehicles";
import TransportStaff from "./TransportStaff/TransportStaff";
import Passenger from "./Passenger/Passenger";
import InstituteVehcileDetail from "./Vehicles/InstituteVehcileDetail/InstituteVehcileDetail";

function TransportMain() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const ids = useSelector((state) => state.idChange);
  const [activeIndex, setActiveIndex] = useState(0);
  const getQuery = useLocation();

  const { oneInstituteDashboard, oneInstituteDashboardRefetch } =
    useOneInstituteDashboard({
      id: ids?.activeIns,
      skip: !ids?.activeIns,
    });

  const { transportDetail, transportDetailRefetch } = useTransportDetail({
    tid: oneInstituteDashboard?.institute?.transportDepart[0],
    skip: !oneInstituteDashboard?.institute?.transportDepart[0],
  });

  // console.info(getQuery);

  return (
    <div className={style.main}>
      <div className={style.fineside}>
        <TransportSidebar
          username={params.username}
          transport={transportDetail?.trans_panel}
        />
      </div>

      <div className={style.finance_container}>
        {getQuery.search?.substring(3, 10) === "vehicle" ? (
          <InstituteVehcileDetail />
        ) : (
          <>
            <div className={style.finance_tab_tabs}>
              <CommonTab
                tabLevel={t("vechiles")}
                onTabAction={() => {
                  navigate(
                    `/q/${params.username}/dash/i/${params.username}/transport`,
                    {
                      state: {
                        ...getQuery.state,
                      },
                    }
                  );
                  setActiveIndex(0);
                }}
                customStyleContainer={{
                  width: "33.3%",
                  marginRight: "2px",
                  borderTopLeftRadius: "6px",
                }}
                customStyleLevel={{ borderTopLeftRadius: "6px" }}
                tabValue={0}
                currentValue={activeIndex}
              />
              <CommonTab
                tabLevel={t("driver_adn_conductor")}
                onTabAction={() => {
                  navigate(
                    `/q/${params.username}/dash/i/${params.username}/transport?a=driver`,
                    {
                      state: {
                        ...getQuery.state,
                      },
                    }
                  );
                  setActiveIndex(1);
                }}
                customStyleContainer={{
                  width: "33.3%",
                }}
                customStyleLevel={{ borderTopLeftRadius: "6px" }}
                tabValue={1}
                currentValue={activeIndex}
              />
              <CommonTab
                tabLevel={t("passengers_and_student")}
                onTabAction={() => {
                  navigate(
                    `/q/${params.username}/dash/i/${params.username}/transport?a=passenger`,
                    {
                      state: {
                        ...getQuery.state,
                      },
                    }
                  );
                  setActiveIndex(2);
                }}
                customStyleContainer={{
                  width: "33.3%",
                  borderTopRightRadius: "6px",
                }}
                customStyleLevel={{ borderTopLeftRadius: "6px" }}
                tabValue={2}
                currentValue={activeIndex}
              />
            </div>

            <div className={style.transportdetails_container}>
              {!getQuery.search && (
                <Vehicles
                  tid={oneInstituteDashboard?.institute?.transportDepart[0]}
                  carryParentState={getQuery.state}
                />
              )}

              {getQuery.search?.substring(3) === "driver" && (
                <TransportStaff
                  tid={oneInstituteDashboard?.institute?.transportDepart[0]}
                  carryParentState={getQuery.state}
                  viewAs="INSTITUTE_TRANSPORT"
                />
              )}
              {getQuery.search?.substring(3) === "passenger" && (
                <Passenger
                  tid={oneInstituteDashboard?.institute?.transportDepart[0]}
                  carryParentState={getQuery.state}
                  viewAs="INSTITUTE_TRANSPORT"
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TransportMain;
