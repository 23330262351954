import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  baseUrl,
  //   patchMethod,
  //   postMethod,
  //   putMethod,
  session,
} from "../BaseUrl";
import { trendingPlateformQuestionFunction } from "./plateform_query_mutation";

export const plateformApi = createApi({
  reducerPath: "plateformApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      if (session()) {
        headers.set("authorization", session());
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    trendingPlateformQuestion: trendingPlateformQuestionFunction(builder),
  }),
});

export const { useTrendingPlateformQuestionQuery } = plateformApi;
