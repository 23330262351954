import React from "react";
import style from "../HostelWardens/HostelWardens.module.css";
import { useTranslation } from "react-i18next";

const RulesCard = ({ rules }) => {
  const { t } = useTranslation();

  return (
    <div className={style.rules_card}>
      <div className={style.rules_card_title}>
        <h6>{rules?.regulation_headline}</h6>
      </div>
      <p>{rules?.regulation_description}</p>
      {rules?.regulation_attachment && (
        <a
          title="Download Attachment"
          href={`https://duoxxvrxc30wv.cloudfront.net/${rules?.regulation_attachment}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
          }}
        >
          <span className={style.download_attachment}>
            {t("download_attachment")}
          </span>
        </a>
      )}
    </div>
  );
};

export default RulesCard;
