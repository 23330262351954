import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { tenderChangeAction } from "../../redux/store/tender-slice";
import style from "./OpenTenders.module.css";

import QLoader from "../../Loader/QLoader";

function InsTenderCard({ setStatus, ltid, tender }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <div className={style.insCareerCardInner}>
      <div
        className={style.insCareerCard}
        onClick={() => {
          dispatch(
            tenderChangeAction.tenderQuery({
              tid: tender?._id,
              ltid: ltid,
            })
          );
          setStatus("tenderDetail");
        }}
      >
        <img src="/images/tender/tender-icon.svg" />
        <div className={style.insCareerCardtext}>
          <h6 className={style.insCareerCardsubject}>
            {tender?.tender_requirement}
          </h6>
          <p className={style.insCareerCarddept}>{tender?.department?.dName}</p>
          <p className={style.insCareerCarddesig}>
            {" "}
            {t("budget_")}: Rs. {tender?.tender_budget}
          </p>
          <p className={style.insCareerCarddesig}>
            {" "}
            {t("bids")}: {tender?.bid_count}
          </p>
        </div>
      </div>

      {disabled && <QLoader />}
    </div>
  );
}

export default InsTenderCard;
