import React from "react";
import { useTranslation } from "react-i18next";

import { imageShowUrl } from "../../../services/BaseUrl";
import style from "../HostelWardens/HostelWardens.module.css";

const HostelFeesCard = ({ applicant }) => {
  const { t } = useTranslation();

  return (
    <div className={style.hostelite_container} title="View Profile">
      <img
        alt="Student Avatar"
        src={
          applicant.photoId !== "1"
            ? `${imageShowUrl}/${applicant?.studentProfilePhoto}`
            : "/images/demo_users/user1.svg"
        }
      />
      <div className={style.hostelite_container_inner}>
        <div className={style.hostelite_container_inner_text}>
          <h6>{`${applicant.studentFirstName} ${
            applicant.studentMiddleName ? applicant.studentMiddleName : ""
          } ${applicant.studentLastName}`}</h6>

          <p>{applicant?.student_unit?.hostel_unit_name ?? ""}</p>
        </div>
        <div className={style.hostelite_container_inner_text}>
          <h6>
            {t("rs")} {". "}
            {applicant?.hostelRemainFeeCount ?? 0}
          </h6>
          <p>{t("pending_fees")}</p>
        </div>
      </div>
    </div>
  );
};

export default HostelFeesCard;
