import React, { useEffect } from "react";
import style from "./EditAdminProfile.module.css";
import { Dialog, Divider } from "@material-ui/core";
import { useState } from "react";
import { imageShowUrl } from "../../../../../services/BaseUrl";
import { TextInput } from "./InputWithoutIcon/InputWithoutIcon";
import { useTranslation } from "react-i18next";
import QLoader from "../../../../../Loader/QLoader";
import LoaderButton from "../../../../../Loader/LoaderButton";
import Crop from "../../../../../ImageCrop/Crop";
import {
  useFileUpload,
  useEditAffiliation,
} from "../../../../../hooks/manageAdmin/manageAdmin-api";

function EditAdminProfile({
  editModal,
  hideModal,
  manage,
  status,
  manageRefetch,
}) {
  const { t } = useTranslation();
  const [file, setFile] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [load, setLoad] = useState(false);
  const [openCrop, setOpenCrop] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [photoURL, setPhotoURL] = useState("");

  const [oneFileUpload] = useFileUpload();
  const [editAffiliationAdmin] = useEditAffiliation();
  const [editData, setEditData] = useState({
    name: manage?.affiliation_admin?.userLegalName,
    username: manage?.affiliation_admin?.username,
    photo: "",
  });

  const [editManageData, setEditManageData] = useState({
    affiliation_name: manage?.affiliation_name,
    // affiliation_username: manage?.affiliation_username,
    photo: manage?.photo,
    delete_pic: manage?.photo,
  });

  const profileImgChange = (e) => {
    const image = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setProfilePhoto(image);
    setPhotoURL(image);
    setOpenCrop(true);
  };

  useEffect(() => {
    if (profilePhoto !== "") {
      setDisabled(true);
      const formData = new FormData();

      formData.append("file", file);
      oneFileUpload({
        fileUpload: formData,
      }).then((res) => {
        setEditManageData({ ...editManageData, photo: res.data?.imageKey });
        setDisabled(false);
      });
    }
  }, [openCrop]);

  const handleEdit = () => {
    setLoad(true);
    editAffiliationAdmin({
      mid: manage?._id,
      manage: editManageData,
    })
      .then((res) => {
        console.info(res.data);
        setLoad(false);
        manageRefetch();
        hideModal(false);
      })
      .catch(() => {});
  };

  return (
    <Dialog open={editModal} onClose={() => hideModal(false)}>
      <>
        {status === "manage" ? (
          <div className={style.editModal}>
            <div className={style.editModalTop}>
              <div />
              <h6>{t("edit_manage_profile")}</h6>
              <img
                src="/images/close-post-icon.svg"
                onClick={() => hideModal(false)}
              />
            </div>
            <div className={style.editModalBody}>
              {profilePhoto ? (
                <div className={style.profilephotocontainer}>
                  <label
                    htmlFor="profile"
                    className={style.profilephotocontainerr}
                  >
                    <img
                      className={style.profilephotoins}
                      src={URL.createObjectURL(file)}
                      alt="ChatAvatar"
                    />
                    <img
                      src="/images/editicon.svg"
                      alt="edit icon"
                      className={style.form_edit_icon1}
                    />
                  </label>

                  <input
                    type="file"
                    id="profile"
                    onChange={profileImgChange}
                    style={{ display: "none" }}
                  />
                </div>
              ) : manage?.photo ? (
                <div className={style.profilephotocontainer}>
                  <label
                    htmlFor="profile"
                    className={style.profilephotocontainerr}
                  >
                    <img
                      alt="logo"
                      className={style.profilephotoins}
                      src={
                        manage?.photoId !== "0"
                          ? `${imageShowUrl}/${manage?.photo}`
                          : "/images/default-avatar.svg"
                      }
                    />
                    <img
                      src="/images/editicon.svg"
                      alt="edit icon"
                      className={style.form_edit_icon1}
                    />
                  </label>

                  <input
                    type="file"
                    id="profile"
                    onChange={profileImgChange}
                    style={{ display: "none" }}
                  />
                </div>
              ) : (
                <div className={style.profilephotocontainer}>
                  <label
                    htmlFor="profile"
                    className={style.profilephotocontainerr}
                  >
                    <img
                      className={style.profilephotoins}
                      src={
                        profilePhoto
                          ? profilePhoto
                          : "/images/user_default_img.svg"
                      }
                      alt="student avatar"
                    />
                    <img
                      src="/images/editicon.svg"
                      alt="edit icon"
                      className={style.form_edit_icon}
                    />
                  </label>

                  <input
                    type="file"
                    id="profile"
                    onChange={profileImgChange}
                    style={{ display: "none" }}
                  />
                </div>
              )}

              <TextInput
                type="text"
                placeholder={t("edit_manage_name")}
                label={t("manage_name")}
                value={editManageData?.affiliation_name}
                handleChange={(e) =>
                  setEditManageData({
                    ...editManageData,
                    affiliation_name: e.target.value,
                  })
                }
                customStyleInput={{
                  width: "100%",
                  padding: "0.6rem",
                }}
              />

              {/* <TextInput
                type="text"
                placeholder={t("edit_manage_user_name")}
                label={t("manage_user_name")}
                value={editManageData?.affiliation_username}
                handleChange={(e) =>
                  setEditManageData({
                    ...editManageData,
                    affiliation_username: e.target.value,
                  })
                }
                customStyleInput={{
                  width: "100%",
                  padding: "0.6rem",
                }}
              /> */}

              <div
                className={style.button_main}
                style={{ marginTop: "1.5vw", width: "100%", cursor: "pointer" }}
                onClick={handleEdit}
              >
                <span>{t("edit_")}</span>
                {load && <LoaderButton />}
              </div>
            </div>
            {openCrop && (
              <Dialog open={openCrop}>
                <Crop
                  setPhotoURL={setPhotoURL}
                  photoURL={photoURL}
                  setFile={setFile}
                  setOpenCrop={setOpenCrop}
                  setProfilePhoto={setProfilePhoto}
                  first={1}
                  second={1}
                />
              </Dialog>
            )}
          </div>
        ) : (
          <div className={style.editModal}>
            <div className={style.editModalTop}>
              <div />
              <h6>{t("edit_admin_profile")}</h6>
              <img
                src="/images/close-post-icon.svg"
                onClick={() => hideModal(false)}
              />
            </div>
            <div className={style.editModalBody}>
              {profilePhoto ? (
                <div className={style.profilephotocontainer}>
                  <label
                    htmlFor="profile"
                    className={style.profilephotocontainerr}
                  >
                    <img
                      className={style.profilephotoins}
                      src={URL.createObjectURL(file)}
                      alt="ChatAvatar"
                    />
                    <img
                      src="/images/editicon.svg"
                      alt="edit icon"
                      className={style.form_edit_icon1}
                    />
                  </label>

                  <input
                    type="file"
                    id="profile"
                    onChange={profileImgChange}
                    style={{ display: "none" }}
                  />
                </div>
              ) : manage?.affiliation_admin?.profilePhoto ? (
                <div className={style.profilephotocontainer}>
                  <label
                    htmlFor="profile"
                    className={style.profilephotocontainerr}
                  >
                    <img
                      className={style.profilephotoins}
                      src={
                        manage?.affiliation_admin?.photoId === "0"
                          ? `${imageShowUrl}/${manage?.affiliation_admin?.profilePhoto}`
                          : "/images/user_default_img.svg"
                      }
                      alt="ChatAvatar"
                    />
                    <img
                      src="/images/editicon.svg"
                      alt="edit icon"
                      className={style.form_edit_icon1}
                    />
                  </label>

                  <input
                    type="file"
                    id="profile"
                    onChange={profileImgChange}
                    style={{ display: "none" }}
                  />
                </div>
              ) : (
                <div className={style.profilephotocontainer}>
                  <label
                    htmlFor="profile"
                    className={style.profilephotocontainerr}
                  >
                    <img
                      className={style.profilephotoins}
                      src={
                        profilePhoto
                          ? profilePhoto
                          : "/images/user_default_img.svg"
                      }
                      alt="student avatar"
                    />
                    <img
                      src="/images/editicon.svg"
                      alt="edit icon"
                      className={style.form_edit_icon}
                    />
                  </label>

                  <input
                    type="file"
                    id="profile"
                    onChange={profileImgChange}
                    style={{ display: "none" }}
                  />
                </div>
              )}

              <TextInput
                type="text"
                placeholder={t("edit_admin_name")}
                label={t("aff_admin_name")}
                value={editData?.name}
                handleChange={(e) =>
                  setEditData({ ...editData, name: e.target.value })
                }
                customStyleInput={{
                  width: "100%",
                  padding: "0.6rem",
                }}
              />
              <TextInput
                type="text"
                placeholder={t("edit_admin_user_name")}
                label={t("aff_user_name")}
                value={editData?.username}
                handleChange={(e) =>
                  setEditData({ ...editData, username: e.target.value })
                }
                customStyleInput={{
                  width: "100%",
                  padding: "0.6rem",
                }}
              />

              <div
                className={style.button_main}
                style={{ marginTop: "1.5vw", width: "100%", cursor: "pointer" }}
                onClick={handleEdit}
              >
                <span>{t("edit_")}</span>
                {disabled && <QLoader />}
              </div>
            </div>
            {openCrop && (
              <Dialog open={openCrop}>
                <Crop
                  setPhotoURL={setPhotoURL}
                  photoURL={photoURL}
                  setFile={setFile}
                  setOpenCrop={setOpenCrop}
                  setProfilePhoto={setProfilePhoto}
                  first={1}
                  second={1}
                />
              </Dialog>
            )}
          </div>
        )}
        {disabled && <QLoader />}
      </>
    </Dialog>
  );
}

export default EditAdminProfile;
