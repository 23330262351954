import React from "react";
import style from "./Vehicles.module.css";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";

const VehicleCard = ({ vehicle, customStyle, carryParentState, viewAs }) => {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <>
      <Link
        state={{ ...carryParentState, vehicleId: vehicle?._id }}
        to={`/q/${params.username}/dash/i/${params.username}/transport?a=vehicle&n=${vehicle?.vehicle_number}`}
        style={
          customStyle
            ? customStyle
            : {
                width: "49%",
              }
        }
      >
        <div className={style.vehical_card_container} style={customStyle}>
          <img src="/images/transport/bus-icon.svg" alt="bus" />
          <div className={style.vehical_card_text}>
            <h6>
              {t("vehicle_no")} {vehicle?.vehicle_number ?? ""}
            </h6>
            <p>
              {t("driver")}
              {`${vehicle?.vehicle_driver?.staffFirstName ?? ""} ${
                vehicle?.vehicle_driver?.staffMiddleName ?? ""
              } ${vehicle?.vehicle_driver?.staffLastName ?? ""}`}
            </p>
            <p>
              {t("conductor")}
              {`${vehicle?.vehicle_conductor?.staffFirstName ?? ""} ${
                vehicle?.vehicle_conductor?.staffMiddleName ?? ""
              } ${vehicle?.vehicle_conductor?.staffLastName ?? ""}`}
            </p>
            <p>
              {t("total_passenger")}
              {" : "}
              {vehicle?.passenger_count ?? 0}
            </p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default VehicleCard;
