import React from "react";
import { imageShowUrl } from "../../../services/BaseUrl";
import style from "../HostelWardens/HostelWardens.module.css";
import HostelTabChangeLink from "../HostelTabChangeLink";
import { Link, useParams } from "react-router-dom";

const UnitCard = ({ hostelUnit, carryParentState, viewAs }) => {
  const params = useParams();
  return (
    <HostelTabChangeLink
      carryParentState={{
        ...carryParentState,
        huid: hostelUnit?._id,
        unitName: hostelUnit?.hostel_unit_name,
      }}
      activeTab={
        viewAs === "RENEWAL" ? "request&renewal&application" : "unit&n=info"
      }
      // accessRole={accessRole}
    >
      <div className={style.hostel_warden_card}>
        <img
          src={
            hostelUnit?.hostel_unit_head?.staffProfilePhoto
              ? `${imageShowUrl}/${hostelUnit?.hostel_unit_head?.staffProfilePhoto}`
              : "/images/demo_users/user1.svg"
          }
          alt=""
        />
        <div className={style.warden_card_text}>
          <h6>{hostelUnit?.hostel_unit_name ?? ""}</h6>
          <p>{`${hostelUnit?.hostel_unit_head?.staffFirstName ?? ""} ${
            hostelUnit?.hostel_unit_head?.staffMiddleName ?? ""
          } ${hostelUnit?.hostel_unit_head?.staffLastName ?? ""}`}</p>
        </div>
      </div>
    </HostelTabChangeLink>
  );
};

export default UnitCard;
