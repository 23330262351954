import React from "react";
import style from "./RouteSlider.module.css";
import { useTranslation } from "react-i18next";

const DotPoint = ({
  customStyle,
  customContainerStyle,
  title,
  subtitle,
  editRoute,
  routeId,
  onRouteClose,
}) => {
  const { t } = useTranslation();

  const onRoute = () => {
    if (editRoute) {
      onRouteClose({
        _id: routeId,
        route_fees: subtitle,
        route_stop: title,
      });
    }
  };
  return (
    <>
      <div
        className={style.dot_point_container}
        style={{ ...customContainerStyle, cursor: "pointer" }}
        onClick={onRoute}
      >
        <div className={style.dot_point} style={customStyle}></div>
        <div>
          <div className={style.dot_point_title}>{title ?? ""}</div>
          {subtitle && (
            <div className={style.dot_point_subtitle}>
              {`${t("rs")}. `}
              {subtitle ?? ""}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DotPoint;
