import React from "react";
import HostelTabChangeLink from "./HostelTabChangeLink";
import style from "../../Finance/Institute/Mainbody/Main.module.css";
import { useTranslation } from "react-i18next";

function HostelMenu({ carryParentState }) {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.item}>
        <HostelTabChangeLink
          carryParentState={carryParentState}
          activeTab="warden"
        >
          <div className={style.itemIcon}>
            <img
              src="/images/members/department-staff-room.svg"
              alt="Class Catalog"
            />
            <p>{t("staff_room")}</p>
          </div>
        </HostelTabChangeLink>
        <HostelTabChangeLink
          carryParentState={carryParentState}
          activeTab="unit"
        >
          <div className={style.itemIcon}>
            <img src="/images/hostel/hostel-unit-icon.svg" alt="completed" />
            <p>{t("hostel_unit")}</p>
          </div>
        </HostelTabChangeLink>
        <HostelTabChangeLink
          carryParentState={carryParentState}
          activeTab="hostelites"
        >
          <div className={style.itemIcon}>
            <img src="/images/hostel/hostelites-icon.svg" alt="completed" />
            <p>{t("total_hostelites")}</p>
          </div>
        </HostelTabChangeLink>
        <HostelTabChangeLink
          carryParentState={carryParentState}
          activeTab="request"
        >
          <div className={style.itemIcon}>
            <img
              src="/images/hostel/class-student-request.svg"
              alt="applications"
            />
            <p>{t("admission_renewal")}</p>
          </div>
        </HostelTabChangeLink>
        <HostelTabChangeLink
          carryParentState={carryParentState}
          activeTab="pending"
        >
          <div className={style.itemIcon}>
            <img src="/images/members/admission-pending.svg" alt="completed" />
            <p>{t("pending_fees")}</p>
          </div>
        </HostelTabChangeLink>
        <HostelTabChangeLink
          carryParentState={carryParentState}
          activeTab="notices"
        >
          <div className={style.itemIcon}>
            <img
              src="/images/hostel/hostel-announcement-icon.svg"
              alt="notices"
            />
            <p>{t("announcement_notice")}</p>
          </div>
        </HostelTabChangeLink>
        <HostelTabChangeLink
          carryParentState={carryParentState}
          activeTab="rules"
        >
          <div className={style.itemIcon}>
            <img src="/images/hostel/hostel-rule-icon.svg" alt="rules" />
            <p>{t("rules")}</p>
          </div>
        </HostelTabChangeLink>
      </div>
    </>
  );
}

export default HostelMenu;
