import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import style from "../HostelWardens/HostelWardens.module.css";
import { useGetHostelRules } from "../../../hooks/member_tab/hostel-api";
import BorderBottom from "../../../SuperAdmin/MainContent/Students/StudentProfile/BorderBottom";
import RulesCard from "./RulesCard";

function HostelRules({ hid }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [add, setAdd] = useState(false);

  const [showingDataLoading, setShowingDataLoading] = useState(true);

  const { getHostelRules, getHostelRulesRefetch } = useGetHostelRules({
    hid: hid,
    skip: !hid,
  });
  useEffect(() => {
    if (hid) {
      setShowingDataLoading(true);
      getHostelRulesRefetch();
    }
  }, [hid, getHostelRulesRefetch]);
  useEffect(() => {
    if (getHostelRules?.one_hostel?.rules) {
      setShowingDataLoading(false);
    } else if (getHostelRules?.one_hostel?.rules?.length === 0)
      setShowingDataLoading(false);
    else {
    }
  }, [getHostelRules?.one_hostel?.rules]);
  return (
    <>
      <div className={style.with_search}>
        <h6>{t("hostel_rule_and_regulation")}</h6>
      </div>

      <BorderBottom
        customStyle={{
          marginTop: "0.5rem",
          marginBottom: "0.6rem",
          width: "100%",
        }}
      />

      {getHostelRules?.one_hostel?.rules?.map((rules) => (
        <RulesCard rules={rules} key={rules?._id} />
      ))}
    </>
  );
}

export default HostelRules;
