import secureLocalStorage from "react-secure-storage";

const postUrl = "/api/v1";
var id = secureLocalStorage.getItem("user");

// ----------------Staff ID Card----------------

export const fetchStaffIdCard = (builder) => {
  return builder.query({
    query: () => `${postUrl}/extra/export/staff/card/${id}`,
  });
};

// -------------------Student ID Card--------------------------------------------
export const extractStudentIDCard = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/extra/export/student/card`,
      method: "POST",
      body: data,
    }),
  });
};

// ----------------Student Export Data----------------

export const fetchStudentExportData = (builder) => {
  return builder.query({
    query: () => `${postUrl}/extra/export/student/all/${id}`,
  });
};

// ----------------Finance Export Data----------------

export const fetchFinanceExportData = (builder) => {
  return builder.query({
    query: (fid) => `${postUrl}/extra/export/student/all/${fid}`,
  });
};
