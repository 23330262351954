import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  baseUrl,
  deleteMethod,
  patchMethod,
  postMethod,
  session,
} from "./BaseUrl";
import {
  dashboardPostFunction,
  profilePostFunction,
  profileSavedPostFunction,
  profileTagPostFunction,
  addTagPeopleFunction,
  createPostTextFunction,
  createPostImageFunction,
  createPostVideoFunction,
  createPostPollQuestionFunction,
  createPostQuestionFunction,
  updatePostFunction,
  deletePostFunction,
  deletePostQuestionFunction,
  reactionPostFunction,
  likePostFunction,
  savePostFunction,
  getAllCommentFunction,
  getAllAnswerFunction,
  postCommentFunction,
  getAllReplyCommentFunction,
  postReplyCommentFunction,
  likeCommentFunction,
  rePostKnowsFunction,
  postReportFunction,
  pollAnswerFunction,
  oneQuestionFunction,
  questionLikeFunction,
  questionDislikeFunction,
  questionSaveFunction,
  questionAnswerSaveFunction,
  questionAddAnswerFunction,
  questionAnswerDeleteFunction,
  questionAllReplyAnswerFunction,
  questionAddReplyAnswerFunction,
  questionAnswerRepostFunction,
  questionRepostLikeFunction,
  profilePostQuestionFunction,
  postInstituteReportFunction,
  questionHelpFunction,
} from "./QueryMutationFunctionPost";

export const postApi = createApi({
  reducerPath: "postApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      if (session()) {
        headers.set("authorization", session());
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDashboardPost: dashboardPostFunction(builder),
    getProfilePost: profilePostFunction(builder),
    getProfileSavedPost: profileSavedPostFunction(builder),
    getProfileTagPost: profileTagPostFunction(builder),
    addTagPeople: addTagPeopleFunction(builder),
    createPostWithText: createPostTextFunction(builder, postMethod),
    createPostWithImage: createPostImageFunction(builder, postMethod),
    createPostWithVideo: createPostVideoFunction(builder, postMethod),
    createPostWithPollQuestion: createPostPollQuestionFunction(
      builder,
      postMethod
    ),
    createPostWithQuestion: createPostQuestionFunction(builder, postMethod),
    createPostUpdate: updatePostFunction(builder, patchMethod),
    createPostDelete: deletePostFunction(builder, deleteMethod),
    createPostDeleteQuestion: deletePostQuestionFunction(builder, deleteMethod),
    reactionPeoplePost: reactionPostFunction(builder),
    likePost: likePostFunction(builder),
    savePost: savePostFunction(builder),
    allCommentPost: getAllCommentFunction(builder),
    allAnswerPostQuestion: getAllAnswerFunction(builder),
    oneCommentPost: postCommentFunction(builder, postMethod),
    allReplyComment: getAllReplyCommentFunction(builder),
    oneReplyComment: postReplyCommentFunction(builder, postMethod),
    likeComment: likeCommentFunction(builder),
    rePostKnows: rePostKnowsFunction(builder),
    postReport: postReportFunction(builder, postMethod),
    postInstituteReport: postInstituteReportFunction(builder, postMethod),
    pollAnswer: pollAnswerFunction(builder, patchMethod),
    oneQuestion: oneQuestionFunction(builder),
    questionLike: questionLikeFunction(builder),
    questionDislike: questionDislikeFunction(builder),
    questionSave: questionSaveFunction(builder),
    questionAnswerSave: questionAnswerSaveFunction(builder),
    questionHelp: questionHelpFunction(builder),
    questionAddAnswer: questionAddAnswerFunction(builder, postMethod),
    questionAnswerDelete: questionAnswerDeleteFunction(builder, deleteMethod),
    questionAllReplyAnswer: questionAllReplyAnswerFunction(builder),
    questionAddReplyAnswer: questionAddReplyAnswerFunction(builder, postMethod),
    questionAnswerRepost: questionAnswerRepostFunction(builder, postMethod),
    questionRepostLike: questionRepostLikeFunction(builder),
    profilePostQuestion: profilePostQuestionFunction(builder),
  }),
});

export const {
  useGetDashboardPostQuery,
  useGetProfilePostQuery,
  useGetProfileSavedPostQuery,
  useGetProfileTagPostQuery,
  useAddTagPeopleQuery,
  useCreatePostWithTextMutation,
  useCreatePostWithImageMutation,
  useCreatePostWithVideoMutation,
  useCreatePostWithPollQuestionMutation,
  useCreatePostWithQuestionMutation,
  useCreatePostUpdateMutation,
  useCreatePostDeleteMutation,
  useCreatePostDeleteQuestionMutation,
  useReactionPeoplePostQuery,
  useLikePostQuery,
  useSavePostQuery,
  useAllCommentPostQuery,
  useAllAnswerPostQuestionQuery,
  useOneCommentPostMutation,
  useAllReplyCommentQuery,
  useOneReplyCommentMutation,
  useLikeCommentQuery,
  useRePostKnowsQuery,
  usePostReportMutation,
  usePostInstituteReportMutation,
  usePollAnswerMutation,
  useOneQuestionQuery,
  useQuestionLikeQuery,
  useQuestionDislikeQuery,
  useQuestionSaveQuery,
  useQuestionAnswerSaveQuery,
  useQuestionHelpQuery,
  useQuestionAddAnswerMutation,
  useQuestionAnswerDeleteMutation,
  useQuestionAllReplyAnswerQuery,
  useQuestionAddReplyAnswerMutation,
  useQuestionAnswerRepostMutation,
  useQuestionRepostLikeQuery,
  useProfilePostQuestionQuery,
} = postApi;
