import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  baseUrl,
  patchMethod,
  postMethod,
  putMethod,
  session,
} from "../BaseUrl";
import {
  classAddDisplayPersonFunction,
  classAllBatchWithClassFunction,
  classAllChecklistFunction,
  classAllComplaintFunction,
  classAllDepartmentWithBatchFunction,
  classAllFeesFunction,
  classAllHolidayFunction,
  classAllLeaveRequestFunction,
  classAllRequestStudentFunction,
  classAllStudentTransferFunction,
  classAllStudentFunction,
  classAllSubjectCompleteFunction,
  classApproveStudentFunction,
  classChecklistAllStudentFunction,
  classCodeRefreshFunction,
  classComplaintSolveFunction,
  classCompleteFunction,
  classCoverPhotoFunction,
  classCreateStudentBehavourFunction,
  classExemptedFeesFunction,
  classInfoUpdateFunction,
  classLeaveRequestActionFunction,
  classOfflineFeesFunction,
  classOneChecklistAssignFunction,
  classOneFeesDetailsFunction,
  classOneFeesFunction,
  classOneFeesSubmit,
  classProfileFunction,
  classProfilePhotoFunction,
  classPromoteStudentFunction,
  classRejectStudentFunction,
  classSettingFunction,
  classReportSettingFunction,
  classSettingReportFunction,
  classStudentAttendanceFunction,
  classStudentAttendanceUpdateFunction,
  classStudentBehavourFunction,
  classStudentCurrentAttendanceFunction,
  classStudentGraceFunction,
  classStudentReportCardAttendanceFunction,
  classStudentReportCardNecessaryFunction,
  classStudentReportCardFinalizeFunction,
  classStudentReportCardFinalizeGraceFunction,
  classStudentReportCardFunction,
  classStudentTransferApprovedFunction,
  classStudentTransferRejectFunction,
  classComplaintDetailFunction,
  studentProfilePhotoFunction,
  studentFormEditFunction,
  studentClassRemoveFunction,
} from "./class_teacher_query_mutation_function";

export const classApi = createApi({
  reducerPath: "classApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      if (session()) {
        headers.set("authorization", session());
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    classProfile: classProfileFunction(builder),
    classProfilePhoto: classProfilePhotoFunction(builder, patchMethod),
    classCoverPhoto: classCoverPhotoFunction(builder, patchMethod),
    classInfoUpdate: classInfoUpdateFunction(builder, postMethod),
    classAddDisplayPerson: classAddDisplayPersonFunction(builder, postMethod),
    classAllStudent: classAllStudentFunction(builder),
    classAllRequestStudent: classAllRequestStudentFunction(builder),
    classApproveStudent: classApproveStudentFunction(builder, postMethod),
    classRejectStudent: classRejectStudentFunction(builder, postMethod),
    classStudentCurrentAttendance:
      classStudentCurrentAttendanceFunction(builder),
    classStudentAttendance: classStudentAttendanceFunction(builder, postMethod),
    classStudentAttendanceUpdate: classStudentAttendanceUpdateFunction(
      builder,
      patchMethod
    ),
    classCodeRefresh: classCodeRefreshFunction(builder, patchMethod),
    classSetting: classSettingFunction(builder, patchMethod),
    classAllHoliday: classAllHolidayFunction(builder),
    classAllChecklist: classAllChecklistFunction(builder),
    classOneChecklistAssign: classOneChecklistAssignFunction(builder),
    classChecklistAllStudent: classChecklistAllStudentFunction(builder),
    classStudentGrace: classStudentGraceFunction(builder, patchMethod),
    classReportSetting: classReportSettingFunction(builder, patchMethod),
    classSettingReport: classSettingReportFunction(builder),
    classStudentBehavour: classStudentBehavourFunction(builder),
    classCreateStudentBehavour: classCreateStudentBehavourFunction(
      builder,
      postMethod
    ),
    classStudentReportCard: classStudentReportCardFunction(builder),
    classStudentReportCardAttendance:
      classStudentReportCardAttendanceFunction(builder),
    classStudentReportCardNecessary:
      classStudentReportCardNecessaryFunction(builder),
    classStudentReportCardFinalize: classStudentReportCardFinalizeFunction(
      builder,
      postMethod
    ),
    classStudentReportCardFinalizeGrace:
      classStudentReportCardFinalizeGraceFunction(builder, patchMethod),
    classAllLeaveRequest: classAllLeaveRequestFunction(builder),
    classLeaveRequestAction: classLeaveRequestActionFunction(
      builder,
      patchMethod
    ),
    classAllComplaint: classAllComplaintFunction(builder),
    classComplaintDetail: classComplaintDetailFunction(builder),
    classComplaintSolve: classComplaintSolveFunction(builder, patchMethod),
    classAllStudentTransfer: classAllStudentTransferFunction(builder),
    classStudentTransferReject: classStudentTransferRejectFunction(
      builder,
      putMethod
    ),
    classStudentTransferApproved: classStudentTransferApprovedFunction(
      builder,
      patchMethod
    ),
    classAllFees: classAllFeesFunction(builder),
    classOneFees: classOneFeesFunction(builder),
    classOneFeesDetails: classOneFeesDetailsFunction(builder),
    classOfflineFees: classOfflineFeesFunction(builder, postMethod),
    classExemptedFees: classExemptedFeesFunction(builder, postMethod),
    classOneFeesSubmit: classOneFeesSubmit(builder, postMethod),
    classAllSubjectComplete: classAllSubjectCompleteFunction(builder),
    classAllDepartmentWithBatch: classAllDepartmentWithBatchFunction(builder),
    classAllBatchWithClass: classAllBatchWithClassFunction(builder),
    classPromoteStudent: classPromoteStudentFunction(builder, postMethod),
    classComplete: classCompleteFunction(builder, patchMethod),

    // for student form Edit
    studentProfilePhoto: studentProfilePhotoFunction(builder, patchMethod),
    studentFormEdit: studentFormEditFunction(builder, patchMethod),
    studentClassRemove: studentClassRemoveFunction(builder, patchMethod),
  }),
});

export const {
  useClassProfileQuery,
  useClassProfilePhotoMutation,
  useClassCoverPhotoMutation,
  useClassInfoUpdateMutation,
  useClassAddDisplayPersonMutation,
  useClassAllStudentQuery,
  useClassAllRequestStudentQuery,
  useClassApproveStudentMutation,
  useClassRejectStudentMutation,
  useClassStudentCurrentAttendanceQuery,
  useClassStudentAttendanceMutation,
  useClassStudentAttendanceUpdateMutation,
  useClassCodeRefreshMutation,
  useClassSettingMutation,
  useClassAllHolidayQuery,
  useClassAllChecklistQuery,
  useClassOneChecklistAssignQuery,
  useClassChecklistAllStudentQuery,
  useClassStudentGraceMutation,
  useClassReportSettingMutation,
  useClassSettingReportQuery,
  useClassStudentBehavourQuery,
  useClassCreateStudentBehavourMutation,
  useClassStudentReportCardQuery,
  useClassStudentReportCardAttendanceQuery,
  useClassStudentReportCardNecessaryQuery,
  useClassStudentReportCardFinalizeMutation,
  useClassStudentReportCardFinalizeGraceMutation,
  useClassAllLeaveRequestQuery,
  useClassLeaveRequestActionMutation,
  useClassAllComplaintQuery,
  useClassComplaintDetailQuery,
  useClassComplaintSolveMutation,
  useClassAllStudentTransferQuery,
  useClassStudentTransferRejectMutation,
  useClassStudentTransferApprovedMutation,
  useClassAllFeesQuery,
  useClassOneFeesQuery,
  useClassOneFeesDetailsQuery,
  useClassOfflineFeesMutation,
  useClassExemptedFeesMutation,
  useClassOneFeesSubmitMutation,
  useClassAllSubjectCompleteQuery,
  useClassAllDepartmentWithBatchQuery,
  useClassAllBatchWithClassQuery,
  useClassPromoteStudentMutation,
  useClassCompleteMutation,
  useStudentProfilePhotoMutation,
  useStudentFormEditMutation,
  useStudentClassRemoveMutation,
} = classApi;
