import React, { useState } from "react";
import style from "./OngoingVacancies.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { careerChangeAction } from "../../../redux/store/career-slice";

function InsCareerCard({ vacancy, setStatus, vid, lcid }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className={style.insCareerCardInner}>
      <div
        className={style.insCareerCard}
        onClick={() => {
          dispatch(
            careerChangeAction.careerQuery({
              vid: vid,
              lcid: lcid,
            })
          );
          setStatus("vacancyDetail");
        }}
      >
        <img src="/images/career/insCareer/career_job.svg" />
        <div className={style.insCareerCardtext}>
          <h6 className={style.insCareerCardsubject}>
            {vacancy?.vacancy_position}
          </h6>
          <p className={style.insCareerCarddept}>
            {vacancy?.department?.dName}
          </p>
          <p className={style.insCareerCarddesig}>
            {vacancy?.vacancy_job_type}
          </p>
          <p className={style.insCareerCarddesig}>
            {t("applications")}: {vacancy?.application_count}{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default InsCareerCard;
