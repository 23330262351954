import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";

const profileChange = createSlice({
  name: "profileChange",
  initialState: {
    searchStatus: secureLocalStorage.getItem("searchStatus"),
    _searchId: secureLocalStorage.getItem("_searchId"),
  },

  reducers: {
    profileQuery(state, action) {
      state.searchStatus = action.payload.searchStatus;

      state._searchId = action.payload._searchId;
      secureLocalStorage.setItem("_searchId", action.payload._searchId);
      secureLocalStorage.setItem("searchStatus", action.payload.searchStatus);
    },
  },
});

export const profileChangeAction = profileChange.actions;

export default profileChange.reducer;
