import React from "react";
import style from "../Vehicles.module.css";
import { useTranslation } from "react-i18next";

import { imageShowUrl } from "../../../../services/BaseUrl";
import BlueLineBar from "./BlueLineBar";
const VehicleHeader = ({ vehicle, onCloseRoute, status, hunit, room }) => {
  const { t } = useTranslation();

  return (
    <div>
      <BlueLineBar />
      <div className={style.class_header_container}>
        <img
          src={
            vehicle?.vehicle_photo
              ? `${imageShowUrl}/${vehicle?.vehicle_photo}`
              : status === "vehicle"
              ? "/images/profileAndCover/transport-cover.svg"
              : status === "hostel"
              ? "/images/profileAndCover/unit-cover.svg"
              : "/images/profileAndCover/room-cover.svg"
          }
          alt="class room background"
          className={style.class_header_image}
        />
        <div className={style.class_header_info}>
          <div className={style.class_header_info_detail}>
            {status === "vehicle" ? (
              <h4>
                {t("vehicle_no")}
                {vehicle?.vehicle_number ?? ""}
              </h4>
            ) : status === "hostel" ? (
              <h4>{hunit?.hostel_unit_name ?? ""}</h4>
            ) : (
              <h4>{room?.room_name ?? ""}</h4>
            )}
            {status === "vehicle" ? (
              <h6>
                {`(${
                  vehicle?.vehicle_type
                    ? vehicle?.vehicle_type === "Own"
                      ? "Own Vehicle"
                      : "Outside Vehicle"
                    : ""
                })`}
              </h6>
            ) : (
              ""
            )}

            {status === "vehicle" ? (
              <>
                <p>{`${t("driver")} ${
                  vehicle?.vehicle_driver?.staffFirstName
                    ? `${vehicle?.vehicle_driver?.staffFirstName ?? ""} ${
                        vehicle?.vehicle_driver?.staffMiddleName ?? ""
                      } ${vehicle?.vehicle_driver?.staffLastName ?? ""}`
                    : vehicle?.vehicle_driver?.userLegalName ?? ""
                }`}</p>
                <p>{`${t("conductor")} ${
                  vehicle?.vehicle_conductor?.staffFirstName
                    ? `${vehicle?.vehicle_conductor?.staffFirstName ?? ""} ${
                        vehicle?.vehicle_conductor?.staffMiddleName ?? ""
                      } ${vehicle?.vehicle_conductor?.staffLastName ?? ""}`
                    : vehicle?.vehicle_conductor?.userLegalName ?? ""
                }`}</p>
              </>
            ) : (
              <p>{`${hunit?.hostel_unit_head?.staffFirstName ?? ""} ${
                hunit?.hostel_unit_head?.staffMiddleName ?? ""
              } ${hunit?.hostel_unit_head?.staffLastName ?? ""}`}</p>
            )}
          </div>
          {status === "hostel" ? (
            <div className={style.class_header_info_bottom}>
              <div className={style.class_header_info_conut}>
                <p>
                  {t("beds")} {" : "}
                  <span>{hunit?.bed_count ?? 0}</span>
                </p>
              </div>
              <div className={style.class_header_info_conut}>
                <p>
                  {t("rooms")} {" : "}
                  <span>{hunit?.room_count ?? 0}</span>
                </p>
              </div>
              <div className={style.class_header_info_conut}>
                <p>
                  {t("occupied")} {" : "}
                  <span>{hunit?.hostelities_count ?? 0}</span>
                </p>
              </div>
            </div>
          ) : status === "room" ? (
            <div className={style.class_header_info_bottom}>
              <div className={style.class_header_info_conut}>
                <p>
                  {t("beds")} {" : "}
                  <span>{room?.bed_count ?? 0}</span>
                </p>
              </div>
              <div className={style.class_header_info_conut}>
                <p>
                  {t("vacant")} {" : "}
                  <span>{room?.vacant_count ?? 0}</span>
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleHeader;
