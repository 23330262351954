const transportBaseUrl = "/api/v1/transport";
const baseUrl = "/api/v1";

export const transportDetailFunction = (builder) => {
  return builder.query({
    query: (tid) => `${transportBaseUrl}/${tid}/dashboard`,
  });
};

export const transportActivateFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${transportBaseUrl}/ins/${data.id}/staff/${data.sid}`,
      method: `POST`,
    }),
  });
};

export const transportEidtActivateFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${baseUrl}/edit/staff/transport/staff/${data.osid}?nsid=${data.nsid}`,
      method: "PATCH",
    }),
  });
};
export const transportCoverUploadFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${baseUrl}/all-images/${data.tid}/transport/cover`,
      method: "PATCH",
      body: data.uploadFile,
    }),
  });
};

export const transportVehicleCoverUploadFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${baseUrl}/all-images/${data.vid}/vehicle/cover`,
      method: "PATCH",
      body: data.uploadFile,
    }),
  });
};
//
export const addVehicleFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${transportBaseUrl}/${data.tid}/new/vehicle`,
      method: "POST",
      body: data.createVehicle,
    }),
  });
};

export const addRouteFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${transportBaseUrl}/vehicle/${data.vid}/new/route`,
      method: "POST",
      body: data.createRoute,
    }),
  });
};

export const updateRouteFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${transportBaseUrl}/vehicle/${data.vid}/route/update?route_status=${data.route_status}`,
      method: "PATCH",
      body: data.modifyRoute,
    }),
  });
};

export const addPassengerFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${transportBaseUrl}/vehicle/${data.vid}/new/passenger`,
      method: "POST",
      body: data.createPassenger,
    }),
  });
};

///
export const getVehicleListFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${transportBaseUrl}/${data.tid}/all/vehicles?page=${data.page}&limit=${data.limit}&search=${data.search}`,
  });
};
export const getPassengerListFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${transportBaseUrl}/${data.tid}/all/passengers?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filter_by}&batch_filter=${data.batch_filter}`,
  });
};

export const getDriverConductorListFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${transportBaseUrl}/${data.tid}/all/vehicles/staff?page=${data.page}&limit=${data.limit}`,
  });
};
export const getVehicleDetailFunction = (builder) => {
  return builder.query({
    query: (vid) => `${transportBaseUrl}/one/vehicle/${vid}/query`,
  });
};

export const getOneVehiclePassengerListFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${transportBaseUrl}/one/vehicle/${data.vid}/query/all/passengers?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filter_by}&batch_filter=${data.batch_filter}`,
  });
};
export const getOneVehicleRouteListFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${transportBaseUrl}/one/vehicle/${data.vid}/route?search=${data.search}`,
  });
};

//
export const collectTransportFeesFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${transportBaseUrl}/${data.tid}/students/${data.sid}/collect/offline`,
      method: "POST",
      body: data.collectFees,
    }),
  });
};
export const requestCashToFinanceFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${transportBaseUrl}/${data.tid}/request/finance`,
      method: "PATCH",
      body: data.submitCash,
    }),
  });
};
export const deleteOneRouteFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${transportBaseUrl}/vehicle/${data.vid}/${data.rid}/destroy`,
      method: "DELETE",
    }),
  });
};

export const deletePassengerFromVehicleFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${transportBaseUrl}/vehicle/${data.vid}/destroy/passenger/${data.sid}`,
      method: "DELETE",
    }),
  });
};
