import React from "react";
import { useTranslation } from "react-i18next";
import style from "../Finance/Institute/Mainbody/Main.module.css";

function InsEventMenu({ setActiveIndex }) {
  const { t } = useTranslation();
  return (
    <div className={style.item}>
      <div
        className={style.itemIcon}
        onClick={() => {
          setActiveIndex(2);
        }}
      >
        <img src="/images/events/event.svg" alt="event" />
        <p>{t("events")}</p>
      </div>

      <div
        className={style.itemIcon}
        onClick={() => {
          setActiveIndex(3);
        }}
      >
        <img src="/images/events/seminar.svg" alt="seminars" />
        <p>{t("seminars")}</p>
      </div>

      <div
        className={style.itemIcon}
        onClick={() => {
          setActiveIndex(4);
        }}
      >
        <img src="/images/events/election.svg" alt="event" />
        <p>{t("elections")}</p>
      </div>

      <div
        className={style.itemIcon}
        onClick={() => {
          setActiveIndex(5);
        }}
      >
        <img src="/images/events/competition.svg" alt="seminars" />
        <p>{t("participative_event")}</p>
      </div>
    </div>
  );
}

export default InsEventMenu;
