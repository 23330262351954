import secureLocalStorage from "react-secure-storage";
const postUrl = "/api/v1";
var id = secureLocalStorage.getItem("user");

// export const getImageFunction = (builder) => {
//   return builder.query({
//     query: (key) => `${postUrl}/${key}`,
//   });
// };

export const privacySettingPatchFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/extra/${data.id}/privacy/institute/feature`,
      method: `${method}`,
      body: data.privacy,
    }),
  });
};

// -------------------User--------------------------------------------

export const userDeActivateAccount = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/user/${id}/deactivate/account`,
      method: "POST",
      body: data,
    }),
  });
};

export const userQCoins = (builder) => {
  return builder.query({
    query: () => ({
      url: `${postUrl}/user/${id}/referals/q-coins`,
      method: "GET",
    }),
  });
};
