import React from "react";
import style from "./InsTenderMain.module.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Sidebar from "../InsTenderSidebar/InsTenderSidebar";
import { useSelector } from "react-redux";
import { useOneInstituteDashboard } from "../../hooks/dashboard/dashboard-api";
import CommonTab from "../../Sidebar/CommonTab";
import { useState } from "react";
import { useGetTenderDetail } from "../../hooks/career-api";
import OngoingTenders from "./OngoingTenders";
import ClosedTenders from "./ClosedTenders";
import TenderDetails from "./TenderDetails";

function InsTenderMain() {
  const { t } = useTranslation();
  const [status, setStatus] = useState("");
  const params = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const ids = useSelector((state) => state.idChange);
  const { oneInstituteDashboard, oneInstituteDashboardRefetch } =
    useOneInstituteDashboard({
      id: ids?.activeIns,
      skip: !ids?.activeIns,
    });

  const { getTenderDetail, getTenderDetailRefetch } = useGetTenderDetail({
    ltid: oneInstituteDashboard?.institute?.tenderDepart[0],
    skip: !oneInstituteDashboard?.institute?.tenderDepart[0],
  });
  console.info(getTenderDetail);
  return (
    <div className={style.main}>
      <div className={style.sidebar}>
        <Sidebar
          username={params.username}
          tender={getTenderDetail?.tender}
          tenderRefetch={getTenderDetailRefetch}
        />
      </div>

      {status == "" && (
        <div className={style.rightbody}>
          <div className={style.admission_tab_tabs}>
            <CommonTab
              tabLevel={t("open_tenders")}
              onTabAction={() => setActiveIndex(0)}
              customStyleContainer={{
                width: "50%",
                marginRight: "2px",
                borderTopLeftRadius: "0.3rem",
              }}
              customStyleLevel={{ borderTopLeftRadius: "0.3rem" }}
              tabValue={0}
              currentValue={activeIndex}
            />
            <CommonTab
              tabLevel={t("closed_tender")}
              onTabAction={() => setActiveIndex(1)}
              customStyleContainer={{
                width: "50%",
                borderTopRightRadius: "0.3rem",
              }}
              customStyleLevel={{ borderTopLeftRadius: "0.3rem" }}
              tabValue={1}
              currentValue={activeIndex}
            />
          </div>

          {status === "" && activeIndex === 0 && (
            <OngoingTenders
              ltid={getTenderDetail?.tender?._id}
              setStatus={setStatus}
            />
          )}
          {status === "" && activeIndex === 1 && (
            <ClosedTenders
              setStatus={setStatus}
              ltid={getTenderDetail?.tender?._id}
            />
          )}
        </div>
      )}

      {status === "tenderDetail" && (
        <div className={style.rightbody}>
          <TenderDetails setStatus={setStatus} />
        </div>
      )}
    </div>
  );
}

export default InsTenderMain;
