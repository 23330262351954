import React from "react";
import style from "./Elections.module.css";
import moment from "moment";
import { useTranslation } from "react-i18next";

function ElectionCard({
  instituteTheme,
  election,
  selectedEid,
  setElectionId,
  scrollToTop,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          backgroundColor:
            selectedEid === election?._id
              ? "rgba(75, 26, 133, 0.1)"
              : "#ffffff",
        }}
        className={style.election_card}
        onClick={() => {
          setElectionId(election?._id);
          scrollToTop();
        }}
      >
        <div
          className={style.election_card_title}
          //   onClick={() => onElectionHandler(election?._id)}
        >
          <img src="/images/election-icon.svg" alt="elections static icon" />
          <h6 className={style.create_new_elections}>
            {election?.election_position}
          </h6>
        </div>

        <hr
          className={style.create_new_elections_hr}
          style={{
            marginLeft: "0",
            marginRight: "0",
          }}
        />
        <div className={style.election_card_info}>
          <p>
            {t("application_start")} :
            <span>
              {moment(election?.election_app_start_date).format("DD MMMM yyyy")}
            </span>
          </p>
          <p>
            {t("voting_date")} :
            <span>
              {moment(election?.election_voting_date).format("DD MMMM yyyy")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ElectionCard;
