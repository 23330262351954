import React, { useState, useCallback } from "react";
import style from "./Events.module.css";
import { useTranslation } from "react-i18next";
import { useGetEventlist } from "../../hooks/member_tab/event-api";
import { useInView } from "react-intersection-observer";
import { debounce } from "lodash";
import EventItem from "./InsEventItem";
import { useEffect } from "react";
import QvipleLoading from "../../Loader/QvipleLoading";
import EventDetail from "./InsEventDetail";

function Events({ eventId, eventCount, setActiveIndex }) {
  const { t } = useTranslation();

  const [ref, inView] = useInView();
  const [page, setPage] = useState(1);
  const [eid, setEid] = useState("");
  const [viewStatus, setViewStatus] = useState("");
  const [search, setSearch] = useState("");
  const [eventList, setEventList] = useState([]);
  const [state, setState] = useState(true);
  const [timeeOut, setTimeeOut] = useState(false);
  const [refetchStatus, setRefetchStatus] = useState(false);
  const [editRefetchStatus, setEditRefetchStatus] = useState(false);
  const [showingDataLoading, setShowingDataLoading] = useState(true);

  const { getEventlist, getEventListRefetch } = useGetEventlist({
    data: {
      eid: eventId,
      page: page,
      limit: 10,
      search: search,
    },
    skip: !eventId,
  });

  useEffect(() => {
    if (inView && state) setPage((page) => page + 1);
  }, [inView, state]);

  useEffect(() => {
    if (eventId) {
      setShowingDataLoading(true);
      getEventListRefetch();
    }
  }, [page, eventId, search, getEventListRefetch]);

  useEffect(() => {
    if (search) {
      setEventList(getEventlist?.all_events);
      setShowingDataLoading(false);
    } else {
      if (refetchStatus) {
        setEventList((prevState) =>
          [
            ...new Set(
              [...getEventlist?.all_events, ...prevState]?.map(JSON.stringify)
            ),
          ]?.map(JSON.parse)
        );
        setShowingDataLoading(false);
        setRefetchStatus(false);
      } else if (editRefetchStatus) {
        setEventList((prevState) =>
          [...new Set([...getEventlist?.all_events]?.map(JSON.stringify))]?.map(
            JSON.parse
          )
        );
        setShowingDataLoading(false);
        setEditRefetchStatus(false);
      } else {
        if (getEventlist?.all_events) {
          setEventList((prevState) =>
            [
              ...new Set(
                [...prevState, ...getEventlist?.all_events]?.map(JSON.stringify)
              ),
            ]?.map(JSON.parse)
          );
          setShowingDataLoading(false);
        } else if (getEventlist?.all_events?.length === 0)
          setShowingDataLoading(false);
        else {
        }
      }
    }
    if (getEventlist?.all_events?.length === 10) setState(true);
    else setState(false);
  }, [getEventlist?.all_events]);

  useEffect(() => {
    setTimeout(() => {
      setTimeeOut(!timeeOut);
      setShowingDataLoading(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDebounce = useCallback(
    debounce((val) => setSearch(val), 500),
    []
  );
  const onSearch = (val) => {
    onDebounce(val);
    setShowingDataLoading(true);
  };

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className={style.remainingFees}>
      <div className={style.remainingFeesTop}>
        <h6>{t("events")}</h6>
        <img
          src="/images/close-post-icon.svg"
          alt=""
          onClick={() => setActiveIndex(0)}
        />
      </div>

      <div className={style.events}>
        <div className={style.eventsleft}>
          <div className={style.searchContainerr}>
            <div className={style.searchContainer}>
              <img alt="" src="/images/search-dept-icon.svg" />
              <input
                type="text"
                onChange={(e) => onSearch(e.target.value)}
                placeholder={t("search-label")}
                required
              />
            </div>
          </div>

          <div className={style.eventsContainer}>
            {eventList?.map((event, index) =>
              eventList?.length === index + 1 ? (
                <div ref={ref} key={index} className={style.itemref}>
                  <EventItem
                    cardRef={ref}
                    event={event}
                    setViewStatus={setViewStatus}
                    scrollToTop={scrollToTop}
                    setEid={setEid}
                    eid={eid}
                  />
                </div>
              ) : (
                <div key={index} className={style.itemref}>
                  <EventItem
                    cardRef={ref}
                    event={event}
                    setViewStatus={setViewStatus}
                    scrollToTop={scrollToTop}
                    setEid={setEid}
                    eid={eid}
                  />
                </div>
              )
            )}
            {showingDataLoading && <QvipleLoading />}
          </div>
        </div>
        {viewStatus === "eventView" ? (
          <EventDetail eid={eid} setViewStatus={setViewStatus} />
        ) : (
          <div className={style.eventsright}>
            <div className={style.eventsrightimgConrainer}>
              <img
                className={style.eventRightimg}
                src="/images/events/event-default.png"
                alt="event"
              />
              <div className={style.eventCount}>
                <h6>{t("events")}</h6>
                <h6>
                  {eventCount} <span>{t("upcoming")}</span>
                </h6>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Events;
