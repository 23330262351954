import React from "react";
import { useTranslation } from "react-i18next";
import style from "../Vehicles.module.css";
import { imageShowUrl } from "../../../../services/BaseUrl";
import BorderBottom from "../../../../SuperAdmin/MainContent/Students/StudentProfile/BorderBottom";

const VehiclePassengerCard = ({
  passenger,
  border,
  onCollectFees,
  viewAs,
  setVehicleMenu,
}) => {
  const { t } = useTranslation();

  const onCollect = (e) => {
    e.stopPropagation();
    if (viewAs === "INSTITUTE_TRANSPORT") {
    } else {
      onCollectFees(passenger);
    }
  };

  const onDeletePassenger = (e) => {
    e.stopPropagation();
    if (viewAs === "INSTITUTE_TRANSPORT") {
    } else {
      setVehicleMenu(passenger);
    }
  };
  return (
    <>
      <div
        className={style.transpotation_card}
        onClick={onCollect}
        title={viewAs === "INSTITUTE_TRANSPORT" ? "" : "Collect fees"}
      >
        <h6
          style={{
            width: "15%",
            paddingLeft: "0.8rem",
          }}
        >
          {passenger?.studentGRNO ?? ""}
        </h6>
        <div
          style={{
            width: "40%",
          }}
          className={style.transpotation_card_detail}
        >
          <img
            src={
              passenger?.studentProfilePhoto
                ? `${imageShowUrl}/${passenger.studentProfilePhoto}`
                : "/images/user-icon.svg"
            }
            alt="avatar"
          />

          <div className={style.transpotation_card_detail_text}>
            <h6>
              {`${passenger?.studentFirstName ?? ""} 
         ${passenger?.studentMiddleName ?? ""}
         ${passenger?.studentLastName ?? ""} `}
            </h6>

            <p>
              {" "}
              {t("class_name")}{" "}
              {`${passenger?.studentClass?.className ?? ""} - ${
                passenger?.studentClass?.classTitle ?? ""
              }`}
            </p>
          </div>
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
          }}
          className={style.transpotation_card_detail_other}
        >
          <div className={style.transpotation_card_detail_other_inner}>
            <img src="/images/email-white.svg" alt="icon" />

            <p>{passenger?.user?.userEmail ?? ""}</p>
          </div>
          <div className={style.transpotation_card_detail_other_inner}>
            <img src="/images/phone-white.svg" alt="icon" />
            <p>{passenger?.user?.userPhoneNumber ?? ""}</p>
          </div>
        </div>

        <h6
          style={{
            width: "15%",
            display: "flex",
            justifyContent: "center",
          }}
          className={style.joining_date}
        >
          {t("rs")}{" "}
          {passenger?.vehicleRemainFeeCount ??
            passenger?.previous_transport_history?.[0]?.vehicleRemainFeeCount ??
            0}
        </h6>
        {viewAs === "INSTITUTE_TRANSPORT" ? (
          ""
        ) : (
          <img
            src="/images/three-24-dot-icon.svg"
            alt="menu icon"
            onClick={onDeletePassenger}
            className={style.menu_icon}
            title="Menu"
          />
        )}
      </div>
      {border && (
        <BorderBottom
          customStyle={{
            width: "100%",
          }}
        />
      )}
    </>
  );
};

export default VehiclePassengerCard;
