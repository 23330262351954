import React, { useEffect, useState } from "react";
import style from "./Elections.module.css";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import ElectionCard from "./ElectionCard";
import {
  useDepartmentAllElection,
  useDepartmentOneElection,
} from "../../hooks/member_tab/event-api";
import moment from "moment";
import QvipleLoading from "../../Loader/QvipleLoading";

function Elections({ eventDeptId, setActiveIndex }) {
  const { t } = useTranslation();
  const [ref, inView] = useInView();
  const [page, setPage] = useState(1);
  const [state, setState] = useState(true);
  const [electionId, setElectionId] = useState("");
  const [showingDataLoading, setShowingDataLoading] = useState(true);

  const [allElection, setAllElection] = React.useState([]);
  const [timeeout, setTimeeout] = useState(false);
  const { departmentAllElection, departmentAllElectionRefetch } =
    useDepartmentAllElection({
      data: {
        did: eventDeptId,
        page: 1,
        limit: 10,
        viewAs: "EVENT_MANAGER",
      },
      skip: !eventDeptId,
    });

  const { departmentOneElection, departmentOneElectionRefetch } =
    useDepartmentOneElection({
      id: electionId,
      skip: !electionId,
    });

  useEffect(() => {
    if (inView && state) setPage((page) => page + 1);
  }, [inView, state]);

  useEffect(() => {
    if (eventDeptId) {
      setShowingDataLoading(true);
      departmentAllElectionRefetch();
    }
  }, [page, eventDeptId, departmentAllElectionRefetch]);

  useEffect(() => {
    setTimeout(() => {
      setTimeeout(!timeeout);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (departmentAllElection?.all_elections) {
      const uniqueDublicate = [
        ...allElection,
        ...departmentAllElection?.all_elections,
      ];
      const uniqueRefind = [
        ...new Set(uniqueDublicate.map(JSON.stringify)),
      ].map(JSON.parse);
      setAllElection(uniqueRefind);
      setShowingDataLoading(false);
    } else if (departmentAllElection?.all_elections?.length === 0)
      setShowingDataLoading(false);
    else {
    }
    if (departmentAllElection?.all_elections?.length === 10) setState(true);
    else setState(false);
  }, [departmentAllElection?.all_elections]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  console.info(departmentAllElection);
  return (
    <div className={style.remainingFees}>
      <div className={style.remainingFeesTop}>
        {electionId !== "" ? (
          <>
            <h6>{t("elections")}</h6>
            <img
              src="/images/close-post-icon.svg"
              alt=""
              onClick={() => setElectionId("")}
            />
          </>
        ) : (
          <>
            <h6>{t("elections")}</h6>
            <img
              src="/images/close-post-icon.svg"
              alt=""
              onClick={() => setActiveIndex(0)}
            />
          </>
        )}
      </div>
      <div className={style.electionCotainer}>
        <div
          className={
            electionId !== ""
              ? `${style.eventlist} ${style.eventlistDetail}`
              : style.eventlist
          }
        >
          {allElection?.length > 0 ? (
            allElection?.map((election, index) => (
              <div
                key={index}
                ref={ref}
                className={
                  electionId !== ""
                    ? `${style.eventCardContainer} ${style.eventCardContainerDetail}`
                    : style.eventCardContainer
                }
              >
                <ElectionCard
                  election={election}
                  setElectionId={setElectionId}
                  instituteTheme=""
                  selectedEid={electionId}
                  scrollToTop={scrollToTop}
                />
              </div>
            ))
          ) : allElection?.length === 0 && timeeout === true ? (
            <div className={style.noData}>
              <img src="/images/nodata.png" alt="" />
            </div>
          ) : (
            <div className={style.loader}>
              <QvipleLoading />
            </div>
          )}
        </div>

        {electionId !== "" && (
          <div className={style.electionDetailsCotainer}>
            <div className={style.election_card_title} id="scrollToTop">
              <img
                src="/images/election-icon.svg"
                alt="elections static icon"
              />
              <h6 className={style.create_new_elections}>
                {departmentOneElection?.elect?.election_position}
              </h6>
            </div>
            <hr className={style.create_new_elections_hr} />
            <div className={style.election_info} style={{ marginTop: "15px" }}>
              <h6>{t("election_detials")}</h6>
              <p>
                {t("apply_from")}{" "}
                {moment(
                  departmentOneElection?.elect?.election_app_start_date
                ).format("DD MMM")}{" "}
                {t("to")}{" "}
                {moment(
                  departmentOneElection?.elect?.election_app_end_date
                ).format("DD MMM yyyy")}
              </p>
              <p>
                {t("candidate_selection")}{" "}
                {moment(
                  departmentOneElection?.elect?.election_selection_date
                ).format("DD MMM yyyy")}
              </p>
              <p>
                {t("start_campaign")}{" "}
                {moment(
                  departmentOneElection?.elect?.election_campaign_date
                ).format("DD MMM")}{" "}
                {t("to")}
                {moment(
                  departmentOneElection?.elect?.election_campaign_last_date
                ).format("DD MMM yyyy")}
              </p>
              <p>
                {t("voting_date")}
                {moment(
                  departmentOneElection?.elect?.election_voting_date
                ).format("DD MMM yyyy")}
              </p>
              <p>
                {t("result_declare")}{" "}
                {moment(
                  departmentOneElection?.elect?.election_result_date
                ).format("DD MMM yyyy")}
              </p>
            </div>
            <hr className={style.create_new_elections_hr1} />
            <div className={style.candidate_container}>
              <p className={style.candidate}>{t("candidate")}</p>
              {/* <AllElectionCandidate electionId={electionId} /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Elections;
