import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  baseUrl,
  deleteMethod,
  patchMethod,
  postMethod,
  session,
} from "../BaseUrl";
import {
  departmentAddDisplayPersonFunction,
  departmentAllChecklistFunction,
  departmentAllClassMasterFunction,
  departmentAllComplaintFunction,
  departmentAllExamFunction,
  departmentAllFeesFunction,
  departmentAllHolidayFunction,
  departmentAllStaffFunction,
  departmentAllSubjectMasterFunction,
  departmentBatchAllClassFunction,
  departmentComplaintDetailFunction,
  departmentComplaintSolveFunction,
  departmentCoverPhotoFunction,
  departmentCreatedChecklistFunction,
  departmentCreatedHolidayFunction,
  departmentCreateExamFunction,
  departmentCreateFeesFunction,
  departmentDeleteHolidayFunction,
  departmentExamDetailFunction,
  departmentInfoUpdateFunction,
  departmentProfileFunction,
  departmentProfilePhotoFunction,
  departmentUpadateChecklistFunction,
} from "./department_head_query_mutation_function";

export const departmentApi = createApi({
  reducerPath: "departmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      if (session()) {
        headers.set("authorization", session());
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    departmentProfile: departmentProfileFunction(builder),
    departmentProfilePhoto: departmentProfilePhotoFunction(
      builder,
      patchMethod
    ),
    departmentCoverPhoto: departmentCoverPhotoFunction(builder, patchMethod),
    departmentInfoUpdate: departmentInfoUpdateFunction(builder, postMethod),
    departmentAddDisplayPerson: departmentAddDisplayPersonFunction(
      builder,
      postMethod
    ),
    departmentAllStaff: departmentAllStaffFunction(builder),
    departmentBatchAllClass: departmentBatchAllClassFunction(builder),
    departmentCreatedHoliday: departmentCreatedHolidayFunction(
      builder,
      postMethod
    ),
    departmentAllHoliday: departmentAllHolidayFunction(builder),
    departmentDeleteHoliday: departmentDeleteHolidayFunction(
      builder,
      deleteMethod
    ),
    departmentCreatedChecklist: departmentCreatedChecklistFunction(
      builder,
      postMethod
    ),
    departmentAllChecklist: departmentAllChecklistFunction(builder),
    departmentUpadateChecklist: departmentUpadateChecklistFunction(
      builder,
      patchMethod
    ),
    departmentAllClassMaster: departmentAllClassMasterFunction(builder),
    departmentAllSubjectMaster: departmentAllSubjectMasterFunction(builder),
    departmentCreateExam: departmentCreateExamFunction(builder, postMethod),
    departmentAllExam: departmentAllExamFunction(builder),
    departmentExamDetail: departmentExamDetailFunction(builder),
    departmentAllComplaint: departmentAllComplaintFunction(builder),
    departmentComplaintDetail: departmentComplaintDetailFunction(builder),
    departmentComplaintSolve: departmentComplaintSolveFunction(
      builder,
      patchMethod
    ),
    departmentAllFees: departmentAllFeesFunction(builder),
    departmentCreateFees: departmentCreateFeesFunction(builder, postMethod),
  }),
});

export const {
  useDepartmentProfileQuery,
  useDepartmentProfilePhotoMutation,
  useDepartmentCoverPhotoMutation,
  useDepartmentInfoUpdateMutation,
  useDepartmentAddDisplayPersonMutation,
  useDepartmentAllStaffQuery,
  useDepartmentBatchAllClassQuery,
  useDepartmentCreatedHolidayMutation,
  useDepartmentAllHolidayQuery,
  useDepartmentDeleteHolidayMutation,
  useDepartmentCreatedChecklistMutation,
  useDepartmentAllChecklistQuery,
  useDepartmentUpadateChecklistMutation,
  useDepartmentAllClassMasterQuery,
  useDepartmentAllSubjectMasterQuery,
  useDepartmentCreateExamMutation,
  useDepartmentAllExamQuery,
  useDepartmentExamDetailQuery,
  useDepartmentAllComplaintQuery,
  useDepartmentComplaintDetailQuery,
  useDepartmentComplaintSolveMutation,
  useDepartmentAllFeesQuery,
  useDepartmentCreateFeesMutation,
} = departmentApi;
