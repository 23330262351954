import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { baseUrl, prepareHeaders } from "./configApi";
import {
  fetchGRStudent,
  fetchStudentInsInfo,
  fetchStudentLeaving,

  addEditableQuery,
  fetchEditableQuery

} from "./certificateAPIBuilder";

export const studenttApi = createApi({
  reducerPath: "studenttApi",
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders: prepareHeaders }),
  endpoints: (builder) => ({
    getStudent: fetchGRStudent(builder),
    getStudentIns: fetchStudentInsInfo(builder),
    getStudentLeaving: fetchStudentLeaving(builder),

    addEditableTextAffiliation: addEditableQuery(builder),
    fetchEditableDetail: fetchEditableQuery(builder)

  }),
});

export const {
  useGetStudentMutation,
  useGetStudentInsQuery,
  useGetStudentLeavingMutation,

  useAddEditableTextAffiliationMutation,
  useFetchEditableDetailQuery

} = studenttApi;
