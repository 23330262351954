import React from "react";
import { useState } from "react";
import style from "./Navright.module.css";
import { useTranslation } from "react-i18next";
import Menu from "./Menu/Menu";
import { imageShowUrl } from "../../../../services/BaseUrl";
import EditAdminProfile from "./EditAdminProfile/EditAdminProfile";

function Navright({ setState, manage }) {
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);
  const [editModal, setEditModal] = useState(false);

  return (
    <div className={style.navright}>
      <div className={style.left}>
        {/* <div className={style.bright}>
          <img alt="img" src="/images/admin/brightness-icon.svg" />
        </div> */}

        {/* <div className={style.mail}>
          <img alt="img" src="/images/admin/mail-icon.svg" />

          <div className={style.mailcount}>0</div>
        </div> */}

        {/* <div className={style.noti} onClick={() => setState(true)}>
          <img alt="img" src="/images/admin/noti-icon.svg" />
          <div className={style.noticount}> manage?.notifyCount}</div>
        </div> */}

        <div className={style.user}>
          <h6>{manage?.affiliation_admin?.username}</h6>
          <p>{t("aff_admin")}</p>
        </div>

        <div className={style.imgContainer} onClick={() => setMenu(!menu)}>
          <img
            alt="img"
            className={style.imageRound}
            src={
              manage?.affiliation_admin?.photoId !== "1"
                ? `${imageShowUrl}/${manage?.affiliation_admin?.profilePhoto}`
                : "/images/demo_users/user3.svg"
            }
          />
        </div>
      </div>

      <Menu
        open={menu}
        onClose={() => setMenu(false)}
        manage={manage}
        setMenu={setMenu}
        setEditModal={setEditModal}
      />

      {editModal && (
        <EditAdminProfile
          editModal={editModal}
          manage={manage}
          hideModal={(w) => setEditModal(w)}
          status="manage admin"
        />
      )}
    </div>
  );
}

export default Navright;
