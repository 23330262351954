import React from "react";
import { useState } from "react";
import { imageShowUrl } from "../../../services/BaseUrl";
import style from "./Navbar.module.css";
import { useTranslation } from "react-i18next";
import Navmid from "./Navmid/Navmid";
import Navright from "./Navright/Navright";
import EditAdminProfile from "./Navright/EditAdminProfile/EditAdminProfile";

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  zIndex: 10,
};

const EDIT_DOT_STYLES = {
  position: "absolute",
  left: "4rem",
  top: "3.5rem",
  zIndex: 10,
};

function Edit({ open, onClose, handleClose, setEditModal, setEdit }) {
  const { t } = useTranslation();
  if (!open) return null;
  return (
    <>
      <div onClick={onClose} style={OVERLAY_STYLES} />
      <div
        style={EDIT_DOT_STYLES}
        className={style.editMenu}
        onClick={() => {
          onClose();

          setEditModal(true);
        }}
      >
        {t("edit")}
      </div>
    </>
  );
}

function Navbar({ setState, manage, status, manageRefetch }) {
  const [edit, setEdit] = useState(false);
  const [editModal, setEditModal] = useState(false);

  return (
    <div
      className={
        status === "institute"
          ? `${style.navbar} ${style.navbarbottom}`
          : style.navbar
      }
    >
      {/* <p onClick={() => manageRefetch()}>click</p> */}
      {status !== "" ? (
        <div className={style.topParent} onClick={() => setEdit(!edit)}>
          <div className={style.top}>
            <img
              alt="logo"
              className={style.logotext}
              src={
                manage?.photoId !== "0"
                  ? `${imageShowUrl}/${manage?.photo}`
                  : "/images/default-avatar.svg"
              }
            />
          </div>
          <div className={style.topParentInner}>
            <span className={style.topText}>
              {manage?.affiliation_name?.substr(0, 20)}
            </span>
          </div>
          {
            <Edit
              open={edit}
              onClose={() => setEdit(false)}
              setEditModal={setEditModal}
            />
          }
        </div>
      ) : (
        <div />
      )}

      <div className={style.navright}>
        <Navmid />
        <Navright setState={setState} manage={manage} />
      </div>
      {editModal && (
        <EditAdminProfile
          manageRefetch={manageRefetch}
          editModal={editModal}
          manage={manage}
          hideModal={(w) => setEditModal(w)}
          status="manage"
        />
      )}
    </div>
  );
}

export default Navbar;

{
  /* {manage?.affiliation_name && manage?.affiliation_name.length <= 20
              ? manage?.affiliation_name
              : manage?.affiliation_name &&
                manage?.affiliation_name?.length > 20
              ? `${manage?.affiliation_name?.match(/.{1,20}/g)[0]} ...`
              : " Affiliated Group Name"} */
}
