import React, { useEffect, useState } from "react";
import style from "../Elections/Elections.module.css";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import {
  useParticipateEventDetails,
  useParticipativeEvent,
} from "../../hooks/member_tab/event-api";
import ParticipateEventCard from "./ParticipateEventCard";
import QvipleLoading from "../../Loader/QvipleLoading";
import moment from "moment";

function ParticipateEvents({ eventDeptId, setIndex, setActiveIndex }) {
  const { t } = useTranslation();
  const [ref, inView] = useInView();
  const [participateId, setParticipateId] = useState("");
  const [page, setPage] = useState(1);
  const [state, setState] = useState(true);
  const [eventList, setEventList] = useState([]);
  const [timeeout, setTimeeout] = useState(false);
  const [showingDataLoading, setShowingDataLoading] = useState(true);
  const { participativeEvent, participativeEventRefetch } =
    useParticipativeEvent({
      data: {
        viewAs: "EVENT_MANAGER",
        did: eventDeptId,
        page: page,
        limit: 10,
      },
      skip: !eventDeptId,
    });

  useEffect(() => {
    if (inView && state) setPage((page) => page + 1);
  }, [inView, state]);

  useEffect(() => {
    if (eventDeptId) {
      setShowingDataLoading(true);
      participativeEventRefetch();
    }
  }, [page, eventDeptId, participativeEventRefetch]);

  useEffect(() => {
    setTimeout(() => {
      setTimeeout(!timeeout);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (participativeEvent?.all_participate) {
      setEventList([
        ...new Set([...eventList, ...participativeEvent?.all_participate]),
      ]);
      setShowingDataLoading(false);
    } else if (participativeEvent?.all_participate?.length === 0)
      setShowingDataLoading(false);
    else {
    }
    if (participativeEvent?.all_participate?.length === 10) setState(true);
    else setState(false);
  }, [participativeEvent?.all_participate]);

  const { participativeEventDetails, participativeEventDetailsRefetch } =
    useParticipateEventDetails({
      pid: participateId,
      skip: !participateId,
    });

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className={style.remainingFees}>
      <div className={style.remainingFeesTop}>
        {participateId !== "" ? (
          <>
            <h6>{t("participative_event")}</h6>
            <img
              src="/images/close-post-icon.svg"
              alt=""
              onClick={() => setParticipateId("")}
            />
          </>
        ) : (
          <>
            <h6>{t("participative_event")}</h6>
            <img
              src="/images/close-post-icon.svg"
              alt=""
              onClick={() => setActiveIndex(0)}
            />
          </>
        )}
      </div>

      <div className={style.electionCotainer}>
        <div
          className={
            participateId !== ""
              ? `${style.eventlist} ${style.eventlistDetail}`
              : style.eventlist
          }
        >
          {eventList?.length > 0 ? (
            eventList?.map((event, index) => (
              <div
                key={index}
                ref={ref}
                className={
                  participateId !== ""
                    ? `${style.eventCardContainer} ${style.eventCardContainerDetail}`
                    : style.eventCardContainer
                }
              >
                <ParticipateEventCard
                  scrollToTop={scrollToTop}
                  setPid={setParticipateId}
                  event={event}
                  pid={participateId}
                  instituteTheme=""
                />
              </div>
            ))
          ) : eventList?.length === 0 && timeeout === true ? (
            <div className={style.noData}>
              <img src="/images/nodata.png" alt="" />
            </div>
          ) : (
            <div className={style.loader}>
              <QvipleLoading />
            </div>
          )}
        </div>

        {participateId !== "" && (
          <div className={style.electionDetailsCotainer}>
            <div className={style.peventCardtop}>
              <div className={style.imgcontainer}>
                <img src="/images/participate-icon.svg" alt="" />
              </div>
              <div className={style.issuedBookItemMidright}>
                <h6>{participativeEventDetails?.part?.event_name}</h6>
                <p>
                  {moment(participativeEventDetails?.part?.event_date).format(
                    "Do MMM YYYY"
                  )}
                </p>
              </div>
            </div>
            <div className={style.peventleftbody}>
              <div className={style.viewRow}>
                <p className={style.viewRowp}>{t("about_event")}</p>
                <h6 className={style.viewRowh6}>
                  {participativeEventDetails?.part?.event_about}
                </h6>
              </div>

              <div className={style.viewRow}>
                <p className={style.viewRowp}>{t("selected_class")}</p>
                {/* <div className={style.classRow}> */}
                {participativeEventDetails?.part?.event_classes?.map(
                  (classs, index) => {
                    if (
                      participativeEventDetails?.part?.event_classes?.length ===
                      index + 1
                    ) {
                      return (
                        <h6 className={style.viewRowh6}>
                          {t("std")} {classs?.className}-{t("class")}{" "}
                          {classs?.classTitle}
                        </h6>
                      );
                    } else {
                      return (
                        <h6 className={style.viewRowh6}>
                          {t("std")} {classs?.className}-{t("class")}{" "}
                          {classs?.classTitle},
                        </h6>
                      );
                    }
                  }
                )}
                {/* </div> */}
              </div>

              <div className={style.viewRowContainer}>
                <div className={style.viewRow} style={{ width: "50%" }}>
                  <p className={style.viewRowp}>
                    {t("last_date_to_application")}
                  </p>
                  <h6 className={style.viewRowh6}>
                    {" "}
                    {moment(participativeEventDetails?.part?.event_date).format(
                      "Do MMM YYYY"
                    )}
                  </h6>
                </div>
                <div className={style.viewRow} style={{ width: "50%" }}>
                  <p className={style.viewRowp}>{t("checklist")}</p>
                  <h6 className={style.viewRowh6}>
                    {participativeEventDetails?.part?.event_checklist_critiria}
                  </h6>
                </div>
              </div>

              <div className={style.viewRowContainer}>
                <div className={style.viewRow} style={{ width: "50%" }}>
                  <p className={style.viewRowp}>{t("fees")}</p>
                  <h6 className={style.viewRowh6}>
                    {participativeEventDetails?.part?.event_fee
                      ? `Rs. ${participativeEventDetails?.part?.event_fee} /-`
                      : `Rs. 0 /-`}
                  </h6>
                </div>

                <div className={style.viewRow} style={{ width: "50%" }}>
                  <p className={style.viewRowp}>{t("rank")}</p>
                  <h6 className={style.viewRowh6}>
                    {participativeEventDetails?.part?.event_ranking_critiria}
                  </h6>
                </div>
              </div>
              {participativeEventDetails?.part?.result_notification ===
              "Declared" ? (
                <div
                  className={`${style.updateResult} ${style.resultDeclared}`}
                >
                  {t("result_declared")}
                </div>
              ) : participativeEventDetails?.part?.event_ranking_critiria ===
                "Yes" ? (
                <div
                  className={`${style.updateResult} ${style.resultnotDeclared}`}
                >
                  {t("result_not_declared")}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ParticipateEvents;
