import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";

const authChange = createSlice({
  name: "authChange",
  initialState: {
    login: secureLocalStorage.getItem("status")
      ? JSON.parse(secureLocalStorage.getItem("status"))
      : false,
    logout: true,
    token: secureLocalStorage.getItem("token")
      ? secureLocalStorage.getItem("token")
      : "",
    id: secureLocalStorage.getItem("user"),
    status: false,
    activeIns: "",
    activeUsername: "",
    activeManageName: "",
    manageId: "",
    managePhoto: "",
    manageName: "",
    manageUsername: "",
    affilliationName: "",
    affilliationUsername: "",
    affilliationPhoto: "",
  },
  reducers: {
    loginQuery(state, action) {
      state.login = action.payload.login;
      state.id = action.payload.id;
      state.token = action.payload.token;
      state.userName = action.payload.userName;
    },
    logoutQuery(state, action) {
      state.logout = action.payload.logout;
    },

    signupChange(state, action) {
      state.status = action.payload.status;
    },
    activeAccount(state, action) {
      state.activeIns = action.payload.activeIns;
      state.activeUsername = action.payload.activeUsername;
      state.activeManageName = action.payload.activeManageName;
    },
    activeManage(state, action) {
      state.manageId = action.payload.manageId;
      state.managePhoto = action.payload.managePhoto;
      state.manageName = action.payload.manageName;
      state.manageUsername = action.payload.manageUsername;
      state.affilliationName = action.payload.affilliationName;
      state.affilliationUsername = action.payload.affilliationUsername;
      state.affilliationPhoto = action.payload.affilliationPhoto;
    },
  },
});

export const authChangeAction = authChange.actions;

export default authChange.reducer;
