import React from "react";
import style from "./CommonSidebar.module.css";

const CommonSidebarBackground = ({
  dynamicImage,
  customStyleBackground,
  customStyleImage,
  edit,
  onEditAction,
}) => {
  return (
    <div
      className={style.common_sidebar_background_container}
      style={{ ...customStyleBackground }}
    >
      <img
        className={style.common_sidebar_background_image}
        style={{ ...customStyleImage }}
        src={dynamicImage}
        alt="backgroud avatar"
      />
      {edit && (
        <img
          className={style.common_sidebar_edit}
          src="/images/user-pencil-edit.svg"
          alt="edit icon"
          onClick={onEditAction}
        />
      )}
    </div>
  );
};

export default CommonSidebarBackground;
