const subjectUrl = "/api/v1/ins";
const examUrl = "/api/v1/exam";
// const settingUrl = "/api/v1/class";
const lockUrl = "/api/v1/batch";
const assignmetUrl = "/api/v1/mcq/subject";
const mcqUrl = "/api/v1/mcq";

export const subjectProfileFunction = (builder) => {
  return builder.query({
    query: (data) => `${subjectUrl}/subject-detail/${data.sid}`,
  });
};

export const subjectAllExamFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${examUrl}/subjectteacher/${data.sid}?page=${data.page}&limit=${data.limit}`,
  });
};

export const subjectOneExamAllStudentFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${examUrl}/allstudents/subjectteacher/${data.sid}/exam/${data.eid}?page=${data.page}&limit=${data.limit}`,
  });
};

export const subjectOneExamMarksFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${examUrl}/allstudents/marks/subjectteacher/${data.sid}`,
      method: `${method}`,
      body: data.marksList,
    }),
  });
};

export const subjectCompleteFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${lockUrl}/subject/${data.sid}`,
      method: `${method}`,
      body: data.status,
    }),
  });
};

export const subjectAllAssignmentFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${assignmetUrl}/${data.sid}/assignment?page=${data.page}&limit=${data.limit}`,
  });
};

export const subjectCreateAssignmentFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${assignmetUrl}/${data.sid}/assignment`,
      method: `${method}`,
      body: data.assignmentCreate,
    }),
  });
};

export const subjectAssignmentSubmitCountFunction = (builder) => {
  return builder.query({
    query: (aid) => `${assignmetUrl}/count/assignment/${aid}`,
  });
};

export const subjectAssignmentSubmitAllStudentFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${assignmetUrl}/assignment/${data.aid}?page=${data.page}&limit=${data.limit}`,
  });
};

export const subjectAssignmentSubmitOneStudentDetailFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${assignmetUrl}/assignment/${data.aid}/student/${data.sid}`,
  });
};

export const subjectAssignmentSubmitOneStudentActionFunction = (
  builder,
  method
) => {
  return builder.mutation({
    query: (data) => ({
      url: `${assignmetUrl}/assignment/${data.aid}/student/${data.sid}`,
      method: `${method}`,
      body: data.assignmentAction,
    }),
  });
};

//=============FOR MCQ RELATED API==============================

export const mcqProfileFunction = (builder) => {
  return builder.query({
    query: (sid) => `${mcqUrl}/subject/profile/${sid}`,
  });
};

export const mcqProfileUpdateFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${mcqUrl}/subject/profile/${data.sid}`,
      method: `${method}`,
      body: data.mcqProfile,
    }),
  });
};

export const mcqUniversalDepartmentFunction = (builder) => {
  return builder.query({
    query: () => `${mcqUrl}/universal/department`,
  });
};

export const mcqUniversalClassFunction = (builder) => {
  return builder.query({
    query: (did) => `${mcqUrl}/universal/class/${did}`,
  });
};

export const mcqUniversalSubjectFunction = (builder) => {
  return builder.query({
    query: (cid) => `${mcqUrl}/universal/subject/${cid}`,
  });
};

export const mcqQuestionFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${mcqUrl}/${data.smid}/question/${data.cmid}?page=${data.page}&limit=${data.limit}&usmid=${data.usmid}&ucmid=${data.ucmid}&search=${data.search}`,
  });
};

export const mcqOneQuestionFunction = (builder) => {
  return builder.query({
    query: (qid) => `${mcqUrl}/question/${qid}/one`,
  });
};
export const mcqCreateQuestionFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${mcqUrl}/${data.smid}/question/${data.cmid}`,
      method: `${method}`,
      body: data.createQuestion,
    }),
  });
};

export const mcqQuestionCountFunction = (builder) => {
  return builder.query({
    query: (data) => `${mcqUrl}/${data.smid}/question/count/${data.cmid}`,
  });
};

export const mcqTestsetFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${mcqUrl}/question/${data.smid}/testset/${data.cmid}?page=${data.page}&limit=${data.limit}&usmid=${data.usmid}&ucmid=${data.ucmid}`,
  });
};

export const mcqCreateTestsetFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${mcqUrl}/question/${data.smid}/testset/${data.cmid}`,
      method: `${method}`,
      body: data.createTestSet,
    }),
  });
};
export const mcqTestsetDetailFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${mcqUrl}/testset/${data.tsid}/detail?page=${data.page}&limit=${data.limit}`,
  });
};

export const mcqTestsetTakenFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${mcqUrl}/subject/${data.sid}/take/testset`,
      method: `${method}`,
      body: data.takeTestset,
    }),
  });
};

export const mcqTestsetTakenListFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${mcqUrl}/subject/${data.sid}/taken/alltestset?page=${data.page}&limit=${data.limit}`,
  });
};

export const mcqTestsetAllotedFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${mcqUrl}/subject/alloted/${data.atsid}/testset/student?page=${data.page}&limit=${data.limit}`,
  });
};
