import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, prepareHeaders } from "../../../services/configApi";

import { oneDashboard } from "./InsDashboardApiBuilder";

export const insDashboardApi = createApi({
  reducerPath: "insDashboardApi",
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders: prepareHeaders }),
  endpoints: (builder) => ({
    oneDashboard: oneDashboard(builder),
  }),
});

export const { useOneDashboardQuery } = insDashboardApi;
