import { transportApi } from "./transportApi";
import {
  addPassengerFunction,
  addRouteFunction,
  addVehicleFunction,
  collectTransportFeesFunction,
  deleteOneRouteFunction,
  deletePassengerFromVehicleFunction,
  getDriverConductorListFunction,
  getOneVehiclePassengerListFunction,
  getOneVehicleRouteListFunction,
  getPassengerListFunction,
  getVehicleDetailFunction,
  getVehicleListFunction,
  requestCashToFinanceFunction,
  transportActivateFunction,
  transportCoverUploadFunction,
  transportDetailFunction,
  transportEidtActivateFunction,
  transportVehicleCoverUploadFunction,
  updateRouteFunction,
} from "./transport_query_mutaion_function";

export const transportDynamicApi = transportApi.injectEndpoints({
  endpoints: (builder) => ({
    transportDetail: transportDetailFunction(builder),
    transportActivate: transportActivateFunction(builder),
    transportEidtActivate: transportEidtActivateFunction(builder),
    transportCoverUpload: transportCoverUploadFunction(builder),
    transportVehicleCoverUpload: transportVehicleCoverUploadFunction(builder),
    //
    addVehicle: addVehicleFunction(builder),
    addRoute: addRouteFunction(builder),
    updateRoute: updateRouteFunction(builder),
    addPassenger: addPassengerFunction(builder),
    //
    getVehicleList: getVehicleListFunction(builder),
    getPassengerList: getPassengerListFunction(builder),
    getDriverConductorList: getDriverConductorListFunction(builder),
    getVehicleDetail: getVehicleDetailFunction(builder),
    getOneVehiclePassengerList: getOneVehiclePassengerListFunction(builder),
    getOneVehicleRouteList: getOneVehicleRouteListFunction(builder),

    ///
    collectTransportFees: collectTransportFeesFunction(builder),
    requestCashToFinance: requestCashToFinanceFunction(builder),
    deleteOneRoute: deleteOneRouteFunction(builder),
    deletePassengerFromVehicle: deletePassengerFromVehicleFunction(builder),
  }),
});

export const {
  useTransportDetailQuery,
  useTransportActivateMutation,
  useTransportEidtActivateMutation,
  useTransportCoverUploadMutation,
  useTransportVehicleCoverUploadMutation,
  //
  useAddVehicleMutation,
  useAddRouteMutation,
  useUpdateRouteMutation,
  useAddPassengerMutation,
  //
  useGetVehicleListQuery,
  useGetPassengerListQuery,
  useGetDriverConductorListQuery,
  useGetVehicleDetailQuery,
  useGetOneVehiclePassengerListQuery,
  useGetOneVehicleRouteListQuery,
  //
  useCollectTransportFeesMutation,
  useRequestCashToFinanceMutation,
  useDeleteOneRouteMutation,
  useDeletePassengerFromVehicleMutation,
} = transportDynamicApi;
