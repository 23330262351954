import React from "react";
import style from "../../TransportManager/TransportSidebar/TransportSidebar.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Goback from "../../Sidebar/Goback";
import CommonSidebarBackground from "../../Sidebar/CommonSidebarBackground";
import { imageShowUrl } from "../../services/BaseUrl";
import CommonSideCard from "../../Sidebar/CommonSideCard";

function HostelSidebar({ username, hostel }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "sticky",
        top: "77px",
        height: "fit-content",
      }}
    >
      <Goback
        linkType={false}
        linkUrl={`/q/${username}/feed`}
        changeIndex={() => navigate(-1)}
      />
      <CommonSidebarBackground
        dynamicImage={
          hostel?.hostel_photo
            ? `${imageShowUrl}/${hostel?.hostel_photo}`
            : "/images/profileAndCover/hostel-cover.svg"
        }
        // onEditAction={onOpenMenu}
      />

      <CommonSideCard
        cardAsHead={true}
        heading={` ${hostel?.hostel_manager?.staffFirstName ?? ""} ${
          hostel?.hostel_manager?.staffMiddleName ?? ""
        } ${hostel?.hostel_manager?.staffLastName ?? ""}`}
        description={t("hostel_manager")}
        customStyleCard={{
          borderRadius: "18px 18px 0px 0px",
          border: "none",
          marginTop: "-2.49vw",
        }}
      />
      <CommonSideCard
        cardAsHead={false}
        heading={t("hostel_beds")}
        description={t("hostel_beds_label")}
        count={hostel?.bed_count ?? 0}
      />
      <CommonSideCard
        cardAsHead={false}
        heading={t("hostel_rooms")}
        description={t("hostel_rooms_label")}
        count={hostel?.room_count ?? 0}
        customStyleCard={{
          borderRadius: "0 0 12px 12px",
          borderBottom: "0.4px solid rgba(0, 0, 0, 0.15)",
        }}
      />
    </div>
  );
}

export default HostelSidebar;
