import React from "react";
import { imageShowUrl } from "../../../services/BaseUrl";
import style from "./HostelWardens.module.css";

const WardenCard = ({ warden }) => {
  return (
    <div className={style.hostel_warden_card}>
      <img
        src={
          warden?.staffProfilePhoto
            ? `${imageShowUrl}/${warden?.staffProfilePhoto}`
            : "/images/demo_users/user1.svg"
        }
        alt=""
      />
      <div className={style.warden_card_text}>
        <h6>{`${warden?.staffFirstName ?? ""} ${
          warden?.staffMiddleName ?? ""
        } ${warden?.staffLastName ?? ""}`}</h6>
        <p>{warden?.hostelUnitDepartment?.[0]?.hostel_unit_name ?? ""}</p>
      </div>
    </div>
  );
};

export default WardenCard;
