import React from "react";
import style from "./InsCareerMain.module.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Sidebar from "../InsCareerSidebar/InsCareerSidebar";
import { useSelector } from "react-redux";
import { useOneInstituteDashboard } from "../../hooks/dashboard/dashboard-api";
import { useGetCareeDetail } from "../../hooks/career-api";
import CommonTab from "../../Sidebar/CommonTab";
import { useState } from "react";
import OngoingVacancies from "./OngoingVacancies/OngoingVacancies";
import VacancyDetails from "../InsCareerMain/OngoingVacancies/VacancyDetails";
import CompletedVacancies from "./CompletedVacancies/CompletedVacancies";

function InsCareerMain() {
  const { t } = useTranslation();
  const params = useParams();
  const careerId = useSelector((state) => state.careerChange);
  const [status, setStatus] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const ids = useSelector((state) => state.idChange);
  const { oneInstituteDashboard, oneInstituteDashboardRefetch } =
    useOneInstituteDashboard({
      id: ids?.activeIns,
      skip: !ids?.activeIns,
    });

  const { getCareerDetail, getCareerDetailRefetch } = useGetCareeDetail({
    lcid: oneInstituteDashboard?.institute?.careerDepart[0],
    skip: !oneInstituteDashboard?.institute?.careerDepart[0],
  });

  return (
    <div className={style.main}>
      <div className={style.sidebar}>
        <Sidebar
          username={params.username}
          career={getCareerDetail?.career}
          careerRefetch={getCareerDetailRefetch}
        />
      </div>

      {status == "" && (
        <div className={style.rightbody}>
          <div className={style.admission_tab_tabs}>
            <CommonTab
              tabLevel={t("ongoing_vacancies")}
              onTabAction={() => setActiveIndex(0)}
              customStyleContainer={{
                width: "50%",
                marginRight: "2px",
                borderTopLeftRadius: "0.3rem",
              }}
              customStyleLevel={{ borderTopLeftRadius: "0.3rem" }}
              tabValue={0}
              currentValue={activeIndex}
            />
            <CommonTab
              tabLevel={t("filled_vacancies")}
              onTabAction={() => setActiveIndex(1)}
              customStyleContainer={{
                width: "50%",
                borderTopRightRadius: "0.3rem",
              }}
              customStyleLevel={{ borderTopLeftRadius: "0.3rem" }}
              tabValue={1}
              currentValue={activeIndex}
            />
          </div>

          {status === "" && activeIndex === 0 && (
            <OngoingVacancies
              lcid={getCareerDetail?.career?._id}
              setStatus={setStatus}
            />
          )}
          {status === "" && activeIndex === 1 && (
            <CompletedVacancies
              setStatus={setStatus}
              lcid={getCareerDetail?.career?._id}
            />
          )}
        </div>
      )}

      {status === "vacancyDetail" && (
        <div className={style.rightbody}>
          <VacancyDetails setStatus={setStatus} />
        </div>
      )}
    </div>
  );
}

export default InsCareerMain;
