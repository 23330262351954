import React from "react";
import style from "../HostelWardens/HostelWardens.module.css";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
import { debounce } from "lodash";
import { useCallback } from "react";
import { useGetHostelHostelitesList } from "../../../hooks/member_tab/hostel-api";
import { useEffect } from "react";
import BorderBottom from "../../../SuperAdmin/MainContent/Students/StudentProfile/BorderBottom";
import HostelitesCard from "./HostelitesCard";
import QvipleLoading from "../../../Loader/QvipleLoading";
import { useTranslation } from "react-i18next";

function Hostelites({ hid, filter_by }) {
  const { t } = useTranslation();
  const [ref, inView] = useInView();
  const [page, setPage] = useState(1);
  const [state, setState] = useState(true);
  const [showingDataLoading, setShowingDataLoading] = useState(true);
  const [hostelitesList, setHostelitesList] = useState([]);
  const [search, setSearch] = useState("");

  const { getHostelHostelitesList, getHostelHostelitesListRefetch } =
    useGetHostelHostelitesList({
      data: {
        hid: hid,
        page: page,
        limit: 10,
        search: search,
        filter_by: filter_by ? filter_by : "",
      },
      skip: !hid,
    });
  useEffect(() => {
    if (hid) {
      setShowingDataLoading(true);
      getHostelHostelitesListRefetch();
    }
  }, [hid, page, search, getHostelHostelitesListRefetch]);

  useEffect(() => {
    if (inView && state) setPage((page) => page + 1);
  }, [inView, state]);

  useEffect(() => {
    if (search) {
      setHostelitesList(getHostelHostelitesList?.all_hostalities);
      setShowingDataLoading(false);
    } else {
      if (getHostelHostelitesList?.all_hostalities) {
        setHostelitesList((prevState) =>
          [
            ...new Set(
              [...prevState, ...getHostelHostelitesList?.all_hostalities]?.map(
                JSON.stringify
              )
            ),
          ]?.map(JSON.parse)
        );
        setShowingDataLoading(false);
      } else if (getHostelHostelitesList?.all_hostalities?.length === 0)
        setShowingDataLoading(false);
      else {
      }
    }
    if (getHostelHostelitesList?.all_hostalities?.length === 10) setState(true);
    else setState(false);
  }, [getHostelHostelitesList?.all_hostalities]);

  const onDebounce = useCallback(
    debounce((val) => setSearch(val), 500),
    []
  );

  const onSearchEvent = (e) => {
    onDebounce(e.target.value);
  };

  return (
    <div>
      <div className={style.with_search}>
        <h6>{t("hostelites")}</h6>
        <section className={style.search_container}>
          <div className={style.search_container_input}>
            <img
              className={style.searchIcon}
              alt="search icon"
              src="/images/search-dash-icon.svg"
            />
            <input
              type="text"
              placeholder="Search Hostelites..."
              onChange={onSearchEvent}
            />
          </div>
        </section>
      </div>
      <BorderBottom
        customStyle={{
          marginTop: "0.5rem",
          width: "100%",
        }}
      />
      {hostelitesList?.map((hostelites, index) =>
        hostelitesList?.length === index + 1 ? (
          <div ref={ref} key={hostelites?._id}>
            <HostelitesCard hostelites={hostelites} viewAs={"INSTITUTE_SIDE"} />
          </div>
        ) : (
          <HostelitesCard
            hostelites={hostelites}
            key={hostelites?._id}
            viewAs={"INSTITUTE_SIDE"}
          />
        )
      )}
      {showingDataLoading && <QvipleLoading />}
    </div>
  );
}

export default Hostelites;
