import {
  useManageAllStaffsQuery,
  useManageAllStudentsQuery,
  useEditAffiliationProfileMutation,
  useFileUploadFunctionMutation,
  useFileUploadAndUpdatedFunctionMutation,
} from "../../services/ManageAdmin/manageAdminAPI";

export const useAllStudents = (args) => {
  const { data: allStudents, refetch: allStudentsRefetch } =
    useManageAllStudentsQuery(
      args.data
      // , { skip: args?.skip }
    );
  return { allStudents, allStudentsRefetch };
};
export const useAllMentors = (args) => {
  const { data: allStaffs, refetch: allStaffssRefetch } =
    useManageAllStaffsQuery(
      args.data
      // , { skip: args?.skip }
    );

  return { allStaffs, allStaffssRefetch };
};

export const useFileUploadAndUpdated = () => {
  const [oneFileUploadAndUpdated] = useFileUploadAndUpdatedFunctionMutation();
  return [oneFileUploadAndUpdated];
};
export const useFileUpload = () => {
  const [oneFileUpload] = useFileUploadFunctionMutation();
  return [oneFileUpload];
};

export const useEditAffiliation = () => {
  const [editAffiliationAdmin] = useEditAffiliationProfileMutation();
  return [editAffiliationAdmin];
};
