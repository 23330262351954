import {
  useTransportDetailQuery,
  useTransportActivateMutation,
  useTransportEidtActivateMutation,
  useTransportCoverUploadMutation,
  useTransportVehicleCoverUploadMutation,
  //
  useAddVehicleMutation,
  useAddRouteMutation,
  useUpdateRouteMutation,
  useAddPassengerMutation,
  //
  useGetVehicleListQuery,
  useGetPassengerListQuery,
  useGetDriverConductorListQuery,
  useGetVehicleDetailQuery,
  useGetOneVehiclePassengerListQuery,
  useGetOneVehicleRouteListQuery,
  //
  useCollectTransportFeesMutation,
  useRequestCashToFinanceMutation,
  useDeleteOneRouteMutation,
  useDeletePassengerFromVehicleMutation,
} from "../../services/transport/transportDynamicApi";

export const useTransportDetail = (args) => {
  const { data: transportDetail, refetch: transportDetailRefetch } =
    useTransportDetailQuery(args.tid, { skip: args.skip });
  return { transportDetail, transportDetailRefetch };
};
export const useTransportActivate = () => {
  const [transportActivate] = useTransportActivateMutation();
  return [transportActivate];
};

export const useTransportEidtActivate = () => {
  const [transportEidtActivate] = useTransportEidtActivateMutation();
  return [transportEidtActivate];
};
export const useTransportCoverUpload = () => {
  const [transportCoverUpload] = useTransportCoverUploadMutation();
  return [transportCoverUpload];
};
export const useTransportVehicleCoverUpload = () => {
  const [transportVehicleCoverUpload] =
    useTransportVehicleCoverUploadMutation();
  return [transportVehicleCoverUpload];
};
//

export const useAddVehicle = () => {
  const [addVehicle] = useAddVehicleMutation();
  return [addVehicle];
};

export const useAddRoute = () => {
  const [addRoute] = useAddRouteMutation();
  return [addRoute];
};
export const useUpdateRoute = () => {
  const [updateRoute] = useUpdateRouteMutation();
  return [updateRoute];
};
export const useAddPassenger = () => {
  const [addPassenger] = useAddPassengerMutation();
  return [addPassenger];
};

//
export const useGetVehicleList = (args) => {
  const { data: getVehicleList, refetch: getVehicleListRefetch } =
    useGetVehicleListQuery(args.data, { skip: args.skip });
  return { getVehicleList, getVehicleListRefetch };
};
export const useGetPassengerList = (args) => {
  const { data: getPassengerList, refetch: getPassengerListRefetch } =
    useGetPassengerListQuery(args.data, { skip: args.skip });
  return { getPassengerList, getPassengerListRefetch };
};
export const useGetDriverConductorList = (args) => {
  const {
    data: getDriverConductorList,
    refetch: getDriverConductorListRefetch,
  } = useGetDriverConductorListQuery(args.data, { skip: args.skip });
  return { getDriverConductorList, getDriverConductorListRefetch };
};
export const useGetVehicleDetail = (args) => {
  const { data: getVehicleDetail, refetch: getVehicleDetailRefetch } =
    useGetVehicleDetailQuery(args.vid, { skip: args.skip });
  return { getVehicleDetail, getVehicleDetailRefetch };
};
export const useGetOneVehiclePassengerList = (args) => {
  const {
    data: getOneVehiclePassengerList,
    refetch: getOneVehiclePassengerListRefetch,
  } = useGetOneVehiclePassengerListQuery(args.data, { skip: args.skip });
  return { getOneVehiclePassengerList, getOneVehiclePassengerListRefetch };
};
export const useGetOneVehicleRouteList = (args) => {
  const {
    data: getOneVehicleRouteList,
    refetch: getOneVehicleRouteListRefetch,
  } = useGetOneVehicleRouteListQuery(args.data, { skip: args.skip });
  return { getOneVehicleRouteList, getOneVehicleRouteListRefetch };
};

//

export const useCollectTransportFees = () => {
  const [collectTransportFees] = useCollectTransportFeesMutation();
  return [collectTransportFees];
};

export const useRequestCashToFinance = () => {
  const [requestCashToFinance] = useRequestCashToFinanceMutation();
  return [requestCashToFinance];
};
export const useDeleteOneRoute = () => {
  const [deleteOneRoute] = useDeleteOneRouteMutation();
  return [deleteOneRoute];
};
export const useDeletePassengerFromVehicle = () => {
  const [deletePassengerFromVehicle] = useDeletePassengerFromVehicleMutation();
  return [deletePassengerFromVehicle];
};
