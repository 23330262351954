import {
  useAddDepartmentMutation,
  useAddNewBatchMutation,
  useAddSubjectMasterMutation,
  useAllClassMasterQuery,
  useAllDepartmentQuery,
  useAllSubjectMasterQuery,
  useViewDepartmentQuery,
  useAddClassMasterMutation,
  useAllApproveStaffQuery,
  useAddClassMutation,
  useAllSubjectOneClassQuery,
  useAddSubjectClassMutation,
  useClassProfileQuery,
  useSetCurrentBatchMutation,
  useAddNewIdenitcalBatchMutation,

  //for department edit
  useEditDepartmentMutation,
  useDeleteDepartmentMutation,
  useEditBatchtMutation,
  useDeleteBatchMutation,
  useDeleteClassMasteMutation,
  useEditDetailClassQuery,
  useEditClassMutation,
  useDeleteClassMutation,
  useDeleteSubjectMasterMutation,
  useEditDetailSubjectQuery,
  useEditSubjectMutation,
  useDeleteSubjectMutation,
  ////
  useUserProfileQuery,
  useUpdateUserProfileMutation,

  //FOR THE INSTITUTE Form Setting OF STAFF and STUDENT
  useInstituteStaffFormQuery,
  useInstituteStudentFormQuery,
  useInstituteFormSettingUpdateMutation,
  //for Staff form Edit
  useStaffProfilePhotoMutation,
  useStaffFormEditMutation,
  useAddStudentToClassMutation,
} from "../../../../services/InstituteDepartment/instituteDepartmentApiDynamic";

export const useAllDepartment = (id) => {
  const { data, refetch } = useAllDepartmentQuery(id);
  return { allDepartment: data, allDepartmentRefetch: refetch };
};

export const useAddNewDepartment = () => {
  const [addDepartment] = useAddDepartmentMutation();
  return [addDepartment];
};

export const useOneViewDepartment = (did) => {
  const { data, refetch } = useViewDepartmentQuery(did);
  return { oneDepartment: data, oneDepartmentRefetch: refetch };
};

export const useOneDepartmentAddBatch = () => {
  const [addBatch] = useAddNewBatchMutation();
  return [addBatch];
};

export const useOneDepartmentAddIdenticalBatch = () => {
  const [addIdenticalBatch] = useAddNewIdenitcalBatchMutation();
  return [addIdenticalBatch];
};

export const useOneDepartmentAddClassMaster = () => {
  const [addClassMaster] = useAddClassMasterMutation();
  return [addClassMaster];
};
export const useOneDepartmentAllClassMaster = (id, did) => {
  const { data, refetch } = useAllClassMasterQuery({ id, did });
  return { allClassMaster: data, allClassMasterRefetch: refetch };
};

export const useOneDepartmentAddSubjectMaster = () => {
  const [addSubjectMaster] = useAddSubjectMasterMutation();
  return [addSubjectMaster];
};

export const useOneDepartmentAllSubjectMaster = (id, did) => {
  const { data, refetch } = useAllSubjectMasterQuery({ id, did });
  return { allSubjectMaster: data, allSubjectMasterRefetch: refetch };
};
export const useOneDepartmentAddClass = () => {
  const [addClass] = useAddClassMutation();
  return [addClass];
};

export const useAllApproveStaffDepartment = (id) => {
  const { data, refetch, isFetching } = useAllApproveStaffQuery(id?.id, {
    skip: id?.skip ? id?.skip : false,
  });
  return {
    allApproveStaff: data,
    allApproveStaffRefetch: refetch,
    isFetching: isFetching,
  };
};

export const useOneDepartmentAllBatch = (id) => {
  const { data, refetch } = useAllDepartmentQuery(id);
  return [data, refetch];
};

export const useOneDepartmentCurrentBatch = (id) => {
  const { data, refetch } = useAllDepartmentQuery(id);
  return [data, refetch];
};

export const useOneDepartmentSetCurrentBatch = () => {
  const [setBatch] = useSetCurrentBatchMutation();
  return [setBatch];
};
export const useOneBatchAllClass = (id) => {
  const { data, refetch } = useAllDepartmentQuery(id);
  return [data, refetch];
};

//FOR THE CLASS PROFILE PAGE
export const useOneDepartmentClassProfile = (args) => {
  const { data, refetch } = useClassProfileQuery(args.cid, {
    skip: args.skip,
  });
  return { oneClassProfile: data, oneClassProfileRefetch: refetch };
};
export const useOneDepartmentAddSubjectClass = () => {
  const [addSubjectInClass] = useAddSubjectClassMutation();
  return [addSubjectInClass];
};
export const useOneDepartmentAllSubjectOneClass = (args) => {
  const { data, refetch } = useAllSubjectOneClassQuery(args.cid, {
    skip: args.skip,
  });
  return { oneClassAllSubject: data, oneClassAllSubjectRefetch: refetch };
};

//FOR THE DEPARTMENT EDIT AND DELETE

export const useOneDepartmenteEdit = () => {
  const [departmentEdit] = useEditDepartmentMutation();
  return [departmentEdit];
};
export const useOneDepartmenteDelete = () => {
  const [departmentDelete] = useDeleteDepartmentMutation();
  return [departmentDelete];
};
export const useOneBatchEdit = () => {
  const [batchEdit] = useEditBatchtMutation();
  return [batchEdit];
};
export const useOneBatchDelete = () => {
  const [batchDelete] = useDeleteBatchMutation();
  return [batchDelete];
};
export const useOneClassMasterDelete = () => {
  const [classMasterDelete] = useDeleteClassMasteMutation();
  return [classMasterDelete];
};
export const useOneClassEditDetail = (data) => {
  const { data: classEditDetail, refetch: classEditDetailRefetch } =
    useEditDetailClassQuery(data.cid, { skip: data.skip });
  return { classEditDetail, classEditDetailRefetch };
};
export const useOneClassEdit = () => {
  const [classEdit] = useEditClassMutation();
  return [classEdit];
};
export const useOneClassDelete = () => {
  const [classDelete] = useDeleteClassMutation();
  return [classDelete];
};
export const useOneSubjectMasterDelete = () => {
  const [subjectMasterDelete] = useDeleteSubjectMasterMutation();
  return [subjectMasterDelete];
};
export const useOneSubjectEditDetail = (data) => {
  const { data: subjectEditDetail, refetch: subjectEditDetailRefetch } =
    useEditDetailSubjectQuery(data.sid, { skip: data.skip });
  return { subjectEditDetail, subjectEditDetailRefetch };
};
export const useOneSubjectEdit = () => {
  const [subjectEdit] = useEditSubjectMutation();
  return [subjectEdit];
};
export const useOneSubjectDelete = () => {
  const [subjectDelete] = useDeleteSubjectMutation();
  return [subjectDelete];
};

//FOR THE INSTITUTE Form Setting OF STAFF and STUDENT

export const useInstituteStaffForm = (args) => {
  const { data, refetch } = useInstituteStaffFormQuery(args.id, {
    skip: args.skip,
  });
  return {
    instituteStaffForm: data,
    instituteStaffFormRefetch: refetch,
  };
};
export const useInstituteStudentForm = (args) => {
  const { data, refetch } = useInstituteStudentFormQuery(args.id, {
    skip: args.skip,
  });
  return {
    instituteStudentForm: data,
    instituteStudentFormRefetch: refetch,
  };
};

export const useInstituteFormSettingUpdate = () => {
  const [instituteFormSettingUpdate] = useInstituteFormSettingUpdateMutation();
  return [instituteFormSettingUpdate];
};
//FOR THE USER PROFILE UPDATE
export const useUserProfile = (id) => {
  const { data, refetch } = useUserProfileQuery(id);
  return { userProfile: data, userProfileRefetch: refetch };
};
export const useUpdateUserProfile = () => {
  const [updateUserProfile] = useUpdateUserProfileMutation();
  return [updateUserProfile];
};

// For staff Form Photo edit

export const useStaffProfilePhoto = () => {
  const [staffProfilePhoto] = useStaffProfilePhotoMutation();
  return [staffProfilePhoto];
};

export const useStaffFormEdit = () => {
  const [staffFormEdit] = useStaffFormEditMutation();
  return [staffFormEdit];
};

export const useAddStudentToClass = () => {
  const [addStudetToClass] = useAddStudentToClassMutation();
  return [addStudetToClass];
};
