import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BorderBottom from "../../../../../SuperAdmin/MainContent/Students/StudentProfile/BorderBottom";
import PopupWrapper from "../../../../../Wrapper/PopupWrapper";
import style from "./RouteSlider.module.css";
import QLoader from "../../../../../Loader/QLoader";
import ShowRouteSlider from "./ShowRouteSlider";

// import RouteMenu from "./RouteMenu";
import { useGetOneVehicleRouteList } from "../../../../../hooks/member_tab/transport-api";
const ShowRoutes = ({ onClose, vid, onRefetch, viewAs }) => {
  const { t } = useTranslation();
  const [disabled, setDiasbled] = useState(false);
  const [addedRouteList, setAddedRouteList] = useState([]);
  const [addIndex, setAddedIndex] = useState("");
  const [openRouteMenu, setOpenRouteMenu] = useState("");
  const [stopList, setStopList] = useState([]);
  const [showingDataLoading, setShowingDataLoading] = useState(true);

  const { getOneVehicleRouteList, getOneVehicleRouteListRefetch } =
    useGetOneVehicleRouteList({
      data: { search: "", vid: vid },
      skip: !vid,
    });

  useEffect(() => {
    if (vid) {
      setShowingDataLoading(true);
      getOneVehicleRouteListRefetch();
    }
  }, [vid, getOneVehicleRouteListRefetch]);
  useEffect(() => {
    if (getOneVehicleRouteList?.route?.boarding_points) {
      setStopList(getOneVehicleRouteList?.route?.boarding_points);
      setShowingDataLoading(false);
    } else if (getOneVehicleRouteList?.route?.boarding_points?.length === 0)
      setShowingDataLoading(false);
    else {
    }
  }, [getOneVehicleRouteList?.route?.boarding_points]);

  useEffect(() => {
    if (stopList) {
      let arr = [];
      for (let i = 0; i < stopList?.length; ++i) {
        if (i === 0) {
        } else {
          arr.push(stopList[i]);
        }
      }
      setAddedRouteList(arr);
    }
  }, [stopList]);

  const onWhereAdd = (index) => {
    let arr = addedRouteList;
    let flag = false;
    for (let i = 0; i < arr?.length; ++i) {
      if (arr[i].stop === "") {
        arr.splice(i, 1);
        flag = true;
      }
    }
    if (flag) {
      setAddedIndex("");
    } else {
      //   if (index === 1) {
      //     arr.splice(0, 0, {
      //       stop: "",
      //       fee: "",
      //       index: index,
      //     });
      //   } else {
      arr.splice(index - 1, 0, {
        stop: "",
        fee: "",
        index: index,
      });
      //   }
      setAddedIndex(index);
      setAddedRouteList(arr);
    }
  };
  const onAddRoute = (val) => {
    setAddedRouteList((prev) => [...prev, val]);
  };
  const onAddInjectRoute = (val) => {
    let arr = addedRouteList;
    for (let i = 0; i < arr?.length; ++i) {
      if (arr[i].stop === "") {
        arr[i].stop = val.stop;
        arr[i].fee = val.fee;
      }
    }
    // console.info("asdsakdkas", arr);
    setAddedRouteList(arr);
    setAddedIndex("");
  };

  const onRouteClose = (val) => {
    setOpenRouteMenu(val);
  };
  return (
    <>
      {!openRouteMenu && (
        <PopupWrapper onClose={onClose}>
          <div className={style.assign_role_container}>
            <section
              className={style.assign_role_title}
              style={{
                position: "relative",
              }}
            >
              <img
                src="/images/arrow-left-fees-icon.svg"
                onClick={onClose}
                alt="back icon"
              />

              <h6>{t("add_vehicle_route")}</h6>
            </section>
            <BorderBottom
              customStyle={{
                width: "100%",
              }}
            />
            <section
              style={{
                padding: "0 0.8rem",
              }}
            >
              <ShowRouteSlider
                onAddRoute={onAddRoute}
                onAddInjectRoute={onAddInjectRoute}
                addedRouteList={addedRouteList}
                onWhereAdd={onWhereAdd}
                addIndex={addIndex}
                viewAs={viewAs}
                onRouteClose={onRouteClose}
              />

              {disabled && <QLoader />}
            </section>
          </div>
        </PopupWrapper>
      )}

      {/* {openRouteMenu && (
        <RouteMenu
          openRouteMenu={openRouteMenu}
          onClose={() => setOpenRouteMenu("")}
          vid={vid}
         
        />
      )} */}
    </>
  );
};

export default ShowRoutes;
