import React from "react";
import Goback from "../../Sidebar/Goback";
import CustomSuspense from "../../Custom/SuspenseLoading/CustomSuspense";
import style from "../../Career/InsCareerSidebar/InsCareerSidebar";
import { useGetLibraryDetail } from "../../hooks/member_tab/library-api";
import { useSelector } from "react-redux";
import CommonSidebarBackground from "../../Sidebar/CommonSidebarBackground";
import { imageShowUrl } from "../../services/BaseUrl";
import { useTranslation } from "react-i18next";
import CommonSideCard from "../../Sidebar/CommonSideCard";
import { useNavigate } from "react-router";

function InsTenderSidebar({ username, tender, tenderRefetch }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <div
      style={{
        position: "sticky",
        top: "85px",
        height: "fit-content",
      }}
    >
      <Goback linkType={true} linkUrl={`/q/${username}/feed`} />
      <CommonSidebarBackground
        dynamicImage={
          tender?.cover
            ? `${imageShowUrl}/${tender?.cover}`
            : "/images/profileAndCover/library-cover.png"
        }
        edit={false}
        onEditAction={() => setOpenMenu(true)}
      />

      <CommonSideCard
        cardAsHead={true}
        heading={``}
        description={""}
        customStyleCard={{
          borderRadius: "1rem 1rem 0 0",
          border: "none",
          marginTop: "-2.49vw",
        }}
      />
      <CommonSideCard
        cardAsHead={false}
        heading={t("open")}
        description={t("open_tenders")}
        count={tender?.open_tender_count || 0}
      />
      <CommonSideCard
        cardAsHead={false}
        heading={t("closed")}
        description={t("closed_tender")}
        count={tender?.closed_tender_count || 0}
        customStyleCard={{
          borderRadius: "0 0 12px 12px",
          borderBottom: "0.4px solid rgba(0, 0, 0, 0.15)",
        }}
      />
    </div>
  );
}

export default InsTenderSidebar;
