import React from "react";
import style from "../HostelWardens/HostelWardens.module.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetAnnouncementListByHostel } from "../../../hooks/member_tab/hostel-api";
import { useEffect } from "react";
import BorderBottom from "../../../SuperAdmin/MainContent/Students/StudentProfile/BorderBottom";
import QvipleLoading from "../../../Loader/QvipleLoading";
import HostelAnnouncementCard from "./HostelAnnouncementCard";

function HostelNotices({ hid }) {
  const { t } = useTranslation();
  const getQuery = useLocation();
  const [add, setAdd] = useState(false);
  const [ref, inView] = useInView();
  const [page, setPage] = useState(1);
  const [state, setState] = useState(true);
  const [showingDataLoading, setShowingDataLoading] = useState(true);
  const [announcementList, setAnnouncementList] = useState([]);
  const [refetchStatus, setRefetchStatus] = useState(false);

  const { getAnnouncementListByHostel, getAnnouncementListByHostelRefetch } =
    useGetAnnouncementListByHostel({
      data: {
        hid: hid,
        page: page,
        limit: 10,
      },
      skip: !hid,
    });
  useEffect(() => {
    if (hid) {
      setShowingDataLoading(true);
      getAnnouncementListByHostelRefetch();
    }
  }, [hid, page, getAnnouncementListByHostelRefetch]);

  useEffect(() => {
    if (inView && state) setPage((page) => page + 1);
  }, [inView, state]);

  useEffect(() => {
    if (refetchStatus) {
      setAnnouncementList(getAnnouncementListByHostel?.announcement);
      setShowingDataLoading(false);
      setRefetchStatus(false);
    } else {
      if (getAnnouncementListByHostel?.announcement) {
        setAnnouncementList((prevState) =>
          [
            ...new Set(
              [...prevState, ...getAnnouncementListByHostel?.announcement]?.map(
                JSON.stringify
              )
            ),
          ]?.map(JSON.parse)
        );
        setShowingDataLoading(false);
      } else if (getAnnouncementListByHostel?.announcement?.length === 0)
        setShowingDataLoading(false);
      else {
      }
    }
    if (getAnnouncementListByHostel?.announcement?.length === 10)
      setState(true);
    else setState(false);
  }, [getAnnouncementListByHostel?.announcement]);

  return (
    <>
      <div className={style.with_search}>
        <h6>{t("hostel_announcement")}</h6>
      </div>
      <BorderBottom
        customStyle={{
          marginTop: "0.5rem",
          marginBottom: "0.6rem",
          width: "100%",
        }}
      />
      {getQuery.state?.viewNotice === "HOSTEL_UNIT_TAB" ? (
        ""
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        ></div>
      )}

      {announcementList?.map((announcement, index) =>
        announcementList?.length === index + 1 ? (
          <div ref={ref} key={announcement?._id}>
            <HostelAnnouncementCard announcement={announcement} />
          </div>
        ) : (
          <HostelAnnouncementCard
            key={announcement?._id}
            announcement={announcement}
          />
        )
      )}

      {showingDataLoading && <QvipleLoading />}
    </>
  );
}

export default HostelNotices;
