export const activateAdmission = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/admission/ins/${data.id}/staff/${data.sid}`,
      method: "POST",
    }),
  });
};

export const editAdmissionFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/edit/staff/admission/staff/${data.osid}?nsid=${data.nsid}`,
      method: "PATCH",
    }),
  });
};

export const fetchAdmission = (builder) => {
  return builder.query({
    query: (ad) => `/admission/${ad}/dashboard/query`,
  });
};

export const fetchOngoing = (builder) => {
  return builder.query({
    query: (ad) =>
      `/admission/${ad.aid}/all/ongoing/application?page=${ad.page}&limit=${ad.limit}`,
  });
};

export const fetchCompleted = (builder) => {
  return builder.query({
    query: (ad) =>
      `/admission/${ad.aid}/all/completed/application/detail?page=${ad.page}&limit=${ad.limit}`,
  });
};

export const fetchRemainingArray = (builder) => {
  return builder.query({
    query: (ad) =>
      `/admission/${ad.aid}/all/remaining/array?page=${ad.page}&limit=${ad.limit}`,
  });
};

export const atInstituteSearchApplication = (builder) => {
  return builder.query({
    query: (ins) =>
      `/admission/${ins.id}/application/list/array?page=${ins.page}&limit=${ins.limit}`,
  });
};

export const userApply = (builder) => {
  return builder.mutation({
    query: (apply) => ({
      url: `/admission/${apply.uid}/user/${apply.aid}/apply`,
      method: "POST",
      body: apply.apply_data,
    }),
  });
};

export const newApplication = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/admission/${data.aid}/new/application`,
      method: "POST",
      body: data.new_Data,
    }),
  });
};

export const fetchRequest = (builder) => {
  return builder.query({
    query: (req) =>
      `/admission/${req.id}/request/application?page=${req.page}&limit=${req.limit}&search=${req.search}`,
  });
};

export const fetchSelect = (builder) => {
  return builder.query({
    query: (sel) =>
      `/admission/${sel.id}/selected/application?page=${sel.page}&limit=${sel.limit}&search=${sel.search}`,
  });
};

export const fetchConfirm = (builder) => {
  return builder.query({
    query: (con) =>
      `/admission/${con.id}/confirmed/application?page=${con.page}&limit=${con.limit}&search=${con.search}`,
  });
};

export const fetchConfirmAll = (builder) => {
  return builder.query({
    query: (con) =>
      `/admission/${con.id}/confirmed/application/all/payload?search=${con.search}`,
  });
};

export const userSelect = (builder) => {
  return builder.mutation({
    query: (select) => ({
      url: `/admission/${select.sid}/student/${select.aid}/select`,
      method: "POST",
    }),
  });
};

export const userCancelApplication = (builder) => {
  return builder.mutation({
    query: (select) => ({
      url: `/admission/${select.sid}/student/${select.aid}/cancel/app`,
      method: "POST",
    }),
  });
};

export const userSelectStatusMode = (builder) => {
  return builder.mutation({
    query: (mode) => ({
      url: `/admission/${mode.sid}/student/pay/mode/${mode.aid}/apply/status/${mode.statusId}`,
      method: "POST",
    }),
  });
};

export const userConfirm = (builder) => {
  return builder.mutation({
    query: (confirm) => ({
      url: `/admission/${confirm.sid}/student/${confirm.aid}/pay/offline/confirm`,
      method: "POST",
      body: confirm.confirm_data,
    }),
  });
};

export const userCancel = (builder) => {
  return builder.mutation({
    query: (cancel) => ({
      url: `/admission/${cancel.sid}/student/${cancel.aid}/pay/refund`,
      method: "POST",
      body: cancel.cancel_data,
    }),
  });
};

export const fetchClasses = (builder) => {
  return builder.query({
    query: (classes) =>
      `/admission/${classes.aid}/application/class/${classes.bid}?page=${classes.page}&search=${classes.search}&limit=${classes.limit}`,
  });
};

export const newClassAllot = (builder) => {
  return builder.mutation({
    query: (allot) => ({
      url: `/admission/student/${allot.aid}/allot/class/${allot.cid}`,
      method: "POST",
      body: allot.allotClass,
    }),
  });
};

export const markComplete = (builder) => {
  return builder.mutation({
    query: (aid) => ({
      url: `/admission/${aid}/application/complete`,
      method: "PATCH",
    }),
  });
};

//depreciated
export const oneStudentFee = (builder) => {
  return builder.query({
    query: (view) => `/admission/${view}/student/view/fee`,
  });
};

export const paidRemainFee = (builder) => {
  return builder.mutation({
    query: (paid) => ({
      url: `/admission/${paid.aid}/paid/remaining/fee/${paid.sid}/student/${paid.appId}`,
      method: "POST",
      body: paid.remain_data,
    }),
  });
};

export const oneApplication = (builder) => {
  return builder.query({
    query: (one) => `/admission/${one}/application/query`,
  });
};

export const allDepartment = (builder) => {
  return builder.query({
    query: (depart) => `/admission/${depart}/all/department`,
  });
};

export const cancelStatusMode = (builder) => {
  return builder.mutation({
    query: (cancel) => ({
      url: `/admission/${cancel.status}/status/cancel/app/${cancel.appId}/student/${cancel.sid}`,
      method: "PATCH",
    }),
  });
};

export const studentAdmission = (builder) => {
  return builder.query({
    query: (student) => `/admission/${student}/fees`,
  });
};

export const collectApplicantDocsFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/admission/${data.sid}/student/${data.aid}/collect/docs?type=${data.install_type}&mode=${data.payment_status}&amount=${data.amount}`,
      method: "POST",
    }),
  });
};

export const admissionBatchListFunction = (builder) => {
  return builder.query({
    query: (aid) => `/admission/${aid}/application/batch`,
  });
};

export const admissionAllottedListFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `/admission/${data.aid}/allotted/application?page=${data.page}&limit=${data.limit}`,
  });
};

export const admissionCancelledListFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `/admission/${data.aid}/cancelled/application?page=${data.page}&limit=${data.limit}`,
  });
};

export const admissionClassMasterListFunction = (builder) => {
  return builder.query({
    query: (did) => `/admission/${did}/all/classmaster`,
  });
};

export const addEnquiryFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/admission/${
        data.aid
      }/new/inquiry?flow_status=${`By Admission Admin`}`,
      method: "POST",
      body: data.enquiry,
    }),
  });
};

export const getAllEnquiryListFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `/admission/${data.aid}/all/inquiry?status=${data.status}&page=${data.page}&limit=${data.limit}`,
  });
};

export const getOneEnquiryFunction = (builder) => {
  return builder.query({
    query: (eid) => `/admission/${eid}/one/inquiry`,
  });
};

export const oneEnquiryActionFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/admission/${data.eid}/inquiry/remark`,
      method: "PATCH",
      body: data.enquiryRemark,
    }),
  });
};

export const directJoinFormAdmissionFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/auth/direct/confirm/join/${data.phoneNumber}/apply/${data.aid}`,
      method: "POST",
      body: data.addStudent,
    }),
  });
};

export const submitAmountToFinanaceFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/finance/${data.aid}/admission/request`,
      method: "POST",
      body: data.submitAmount,
    }),
  });
};
