import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { baseUrl, prepareHeaders } from "../configApi";
import {
  manageAuthentication,
  manageDashboard,
  manageInstitute,
  manageRequestAll,
  manageStatus,
  manageFinance,
  manageAdmission,
  manageUser,
  oneInstituteProfile,
  manageAllStaffs,
  manageAllStudents,
  editAffiliationProfile,
  fileUploadFunction,
  fileUploadAndUpdatedFunction,
} from "./manageAdminBuilder";

export const manageAdminAPI = createApi({
  reducerPath: "manageAdminAPI",
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders: prepareHeaders }),
  tagTypes: ["Admin"],
  endpoints: (builder) => ({
    renderAuthentication: manageAuthentication(builder),
    renderManageDashboard: manageDashboard(builder),
    renderAllInstitute: manageInstitute(builder),
    renderAllRequest: manageRequestAll(builder),
    renderManageStatus: manageStatus(builder),
    renderFinance: manageFinance(builder),
    renderAdmission: manageAdmission(builder),
    renderUserSearch: manageUser(builder),
    renderInsProfile: oneInstituteProfile(builder),
    manageAllStaffs: manageAllStaffs(builder),
    manageAllStudents: manageAllStudents(builder),
    editAffiliationProfile: editAffiliationProfile(builder),
    fileUploadFunction: fileUploadFunction(builder),
    fileUploadAndUpdatedFunction: fileUploadAndUpdatedFunction(builder),
  }),
});

export const {
  useRenderAuthenticationMutation,
  useRenderManageDashboardQuery,
  useRenderAllInstituteQuery,
  useRenderAllRequestQuery,
  useRenderManageStatusMutation,
  useRenderFinanceQuery,
  useRenderAdmissionQuery,
  useRenderUserSearchQuery,
  useRenderInsProfileQuery,
  useManageAllStaffsQuery,
  useManageAllStudentsQuery,
  useEditAffiliationProfileMutation,
  useFileUploadFunctionMutation,
  useFileUploadAndUpdatedFunctionMutation,
} = manageAdminAPI;
