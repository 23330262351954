import { createSlice } from "@reduxjs/toolkit";

const admissionChange = createSlice({
  name: "admissionChange",
  initialState: {
    aid: "",
    applyId: "",
    studentAppId: "",
    studentAdminId: "",
  },
  reducers: {
    admissionQuery(state, action) {
      state.aid = action.payload.aid;
      state.applyId = action.payload.applyId;
      state.studentAppId = action.payload.studentAppId;
      state.studentAdminId = action.payload.studentAdminId
    },
  },
});

export const admissionChangeAction = admissionChange.actions;

export default admissionChange.reducer;
