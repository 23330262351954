import React from "react";
import style from "./TransportSidebar.module.css";
import CommonSideCard from "../../Sidebar/CommonSideCard";
import Goback from "../../Sidebar/Goback";
import CommonSidebarBackground from "../../Sidebar/CommonSidebarBackground";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { imageShowUrl } from "../../services/BaseUrl";

function TransportSidebar({ username, transport }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "sticky",
        top: "77px",
        height: "fit-content",
      }}
    >
      <Goback
        linkType={false}
        linkUrl={`/q/${username}/feed`}
        changeIndex={() => navigate(-1)}
      />
      <CommonSidebarBackground
        dynamicImage={
          transport?.transport_photo
            ? `${imageShowUrl}/${transport?.transport_photo}`
            : "/images/profileAndCover/transport-cover.svg"
        }
        // onEditAction={onOpenMenu}
      />

      <CommonSideCard
        cardAsHead={true}
        heading={` ${transport?.transport_manager?.staffFirstName || ""} ${
          transport?.transport_manager?.staffMiddleName
            ? transport?.transport_manager?.staffMiddleName
            : ""
        } ${transport?.transport_manager?.staffLastName || ""}`}
        description={t("transport_manager")}
        customStyleCard={{
          borderRadius: "1rem 1rem 0 0",
          border: "none",
          marginTop: "-2.49vw",
        }}
      />
      <CommonSideCard
        cardAsHead={false}
        heading={t("total_vehicle")}
        description={t("total_vehicle_label")}
        count={transport?.vehicle_count ?? 0}
      />
      <CommonSideCard
        cardAsHead={false}
        heading={t("total_transport_staff")}
        description={t("total_transport_staff_label")}
        count={transport?.transport_staff_count ?? 0}
      />
      <CommonSideCard
        cardAsHead={false}
        heading={t("total_passenger")}
        description={t("total_passenger_label")}
        count={transport?.passenger_count ?? 0}
      />
      <CommonSideCard
        c
        cardAsHead={false}
        heading={t("total_pending_fees")}
        description={t("total_pending_fees_label")}
        count={`${t("rs")} ${transport?.remaining_fee ?? 0}`}
        // customStyleCard={{
        //   borderRadius: "0 0 12px 12px",
        //   borderBottom: "0.4px solid rgba(0, 0, 0, 0.15)",
        // }}
      />
    </div>
  );
}

export default TransportSidebar;
