import React from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "./CommonSidebar.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { useGoback } from "../hooks/GoBack/go-back";

const Goback = ({ changeIndex, linkType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const backValue = useSelector((state) => state.idChange.universalBack);

  return (
    <div className={style.goback_container}>
      {!linkType && changeIndex && (
        <div className={style.goback_container_text} onClick={changeIndex}>
          <img src="/images/arr-left-white.svg" alt="go back icon" />
          <h6 className={style.goback_container_text_go}>{t("go_back")}</h6>
        </div>
      )}
      {!backValue?.childList?.[backValue?.childHeight]?.linkType &&
        !changeIndex && (
          <div
            className={style.goback_container_text}
            onClick={() => navigate(-1)}
          >
            <img src="/images/arr-left-white.svg" alt="go back icon" />
            <h6 className={style.goback_container_text_go}>{t("go_back")}</h6>
          </div>
        )}
      {backValue?.childList?.[backValue?.childHeight]?.linkType && (
        <Link to={backValue?.childList[backValue?.childHeight]?.linkUrl}>
          <div className={style.goback_container_text}>
            <img src="/images/arr-left-white.svg" alt="go back icon" />
            <h6 className={style.goback_container_text_go}>{t("go_back")}</h6>
          </div>
        </Link>
      )}
    </div>
  );
};

export default Goback;
