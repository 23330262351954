import React, { useState } from "react";
import style from "../../TransportManager/TransportMain/TransportMain.module.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useOneInstituteDashboard } from "../../hooks/dashboard/dashboard-api";
import { useHostelDetail } from "../../hooks/member_tab/hostel-api";
import HostelSidebar from "./HostelSidebar";
import CommonTab from "../../Sidebar/CommonTab";
import HostelMenu from "./HostelMenu";
import HostelFund from "./HostelFund";
import HostelWardens from "./HostelWardens/HostelWardens";
import Hostelites from "./Hostelites/Hostelites";
import HostelNotices from "./HostelNotices/HostelAnnouncementNotices";
import HostelRules from "./HostelRules/HostelRules";
import HostelFees from "./HostelFees/HostelFees";
import HostelApplications from "./HostelApplications/HostelApplications";
import HostelUnit from "./HostelUnit/HostelUnit";
import HostelUnitProfile from "./HostelUnit/HostelUnitProfile/HostelUnitProfile";
import HostelRoomProfile from "./HostelUnit/HostelUnitProfile/HostelUnitRooms/HostelRoomProfile/HostelRoomProfile";

function HostalManagerMain() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const ids = useSelector((state) => state.idChange);
  const [activeIndex, setActiveIndex] = useState(0);
  const getQuery = useLocation();

  const { oneInstituteDashboard, oneInstituteDashboardRefetch } =
    useOneInstituteDashboard({
      id: ids?.activeIns,
      skip: !ids?.activeIns,
    });

  const { hostelDetail, hostelDetailRefetch } = useHostelDetail({
    hid: oneInstituteDashboard?.institute?.hostelDepart[0],
    skip: !oneInstituteDashboard?.institute?.hostelDepart[0],
  });

  return (
    <div className={style.main}>
      <div className={style.fineside}>
        <HostelSidebar
          username={params.username}
          hostel={hostelDetail?.one_hostel}
        />
      </div>

      <div className={style.finance_container}>
        {getQuery.search?.substring(3, 10) === "warden" ? (
          <div className={style.institute_hostel_container}>
            <HostelWardens
              hid={oneInstituteDashboard?.institute?.hostelDepart[0]}
            />
          </div>
        ) : getQuery.search?.substring(3) === "hostelites" ? (
          <div className={style.institute_hostel_container}>
            <Hostelites
              hid={oneInstituteDashboard?.institute?.hostelDepart[0]}
            />
          </div>
        ) : getQuery.search?.substring(3) === "notices" ? (
          <div className={style.institute_hostel_container}>
            <HostelNotices
              hid={oneInstituteDashboard?.institute?.hostelDepart[0]}
            />
          </div>
        ) : getQuery.search?.substring(3) === "rules" ? (
          <div className={style.institute_hostel_container}>
            <HostelRules
              hid={oneInstituteDashboard?.institute?.hostelDepart[0]}
            />
          </div>
        ) : getQuery.search?.substring(3) === "pending" ? (
          <div className={style.institute_hostel_container}>
            <HostelFees
              hid={oneInstituteDashboard?.institute?.hostelDepart[0]}
            />
          </div>
        ) : getQuery.search?.substring(3) === "request" ? (
          <HostelApplications
            hid={oneInstituteDashboard?.institute?.hostelDepart[0]}
          />
        ) : getQuery.search?.substring(3) === "unit" ? (
          <div className={style.institute_hostel_container}>
            <HostelUnit
              hid={oneInstituteDashboard?.institute?.hostelDepart[0]}
            />
          </div>
        ) : getQuery.search?.substring(3) === "unit&n=info" ? (
          <HostelUnitProfile
            hid={oneInstituteDashboard?.institute?.hostelDepart[0]}
            carryParentState={getQuery.state}
          />
        ) : getQuery?.search?.substring(3) === "unit&n=room" ? (
          <HostelRoomProfile
            hid={oneInstituteDashboard?.institute?.hostelDepart[0]}
            carryParentState={getQuery.state}
          />
        ) : (
          <>
            <div className={style.finance_tab_tabs}>
              <CommonTab
                tabLevel={t("menu")}
                onTabAction={() => {
                  navigate(
                    `/q/${params.username}/dash/i/${params.username}/hostel`,
                    {
                      state: {
                        ...getQuery.state,
                      },
                    }
                  );
                  setActiveIndex(0);
                }}
                customStyleContainer={{
                  width: "50%",
                  marginRight: "2px",
                  borderTopLeftRadius: "6px",
                }}
                customStyleLevel={{ borderTopLeftRadius: "6px" }}
                tabValue={0}
                currentValue={activeIndex}
              />
              <CommonTab
                tabLevel={t("funds")}
                onTabAction={() => {
                  navigate(
                    `/q/${params.username}/dash/i/${params.username}/hostel?a=funds`,
                    {
                      state: {
                        ...getQuery.state,
                      },
                    }
                  );
                  setActiveIndex(1);
                }}
                customStyleContainer={{
                  width: "50%",
                }}
                customStyleLevel={{ borderTopLeftRadius: "6px" }}
                tabValue={1}
                currentValue={activeIndex}
              />
            </div>

            <div className={style.transportdetails_container}>
              {!getQuery.search && (
                <HostelMenu
                  tid={oneInstituteDashboard?.institute?.transportDepart[0]}
                  carryParentState={getQuery.state}
                />
              )}

              {getQuery.search?.substring(3) === "funds" && (
                <HostelFund
                  onlineFee={hostelDetail?.one_hostel?.onlineFee}
                  offlineFee={hostelDetail?.one_hostel?.offlineFee}
                  exemptAmount={hostelDetail?.one_hostel?.exemptAmount}
                  remainingFeeCount={
                    hostelDetail?.one_hostel?.remainingFeeCount
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default HostalManagerMain;
