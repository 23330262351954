import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { imageShowUrl } from "../../../../../../services/BaseUrl";
import style from "../../HostelUnitProfile.module.css";

const RoomHosteliteCard = ({ hostelite, vacant, index, viewAs }) => {
  const { t } = useTranslation();

  return (
    <>
      <section className={style.hostelite_container}>
        {vacant ? (
          <img
            alt="Student Request Avatar"
            src={"/images/hostel/vacant-plus-icon.svg"}
          />
        ) : (
          <img
            alt="Student Request Avatar"
            src={
              hostelite?.bed_allotted_candidate?.photoId !== "1"
                ? `${imageShowUrl}/${hostelite?.bed_allotted_candidate?.studentProfilePhoto}`
                : "/images/user_default_img.svg"
            }
          />
        )}
        <div className={style.hostelite_container_inner}>
          <div className={style.hostelite_container_inner_text}>
            {vacant ? (
              <h6>{t("vacant_bed")}</h6>
            ) : (
              <h6>{`${
                hostelite?.bed_allotted_candidate?.studentFirstName ?? ""
              } ${hostelite?.bed_allotted_candidate?.studentMiddleName ?? ""} ${
                hostelite?.bed_allotted_candidate?.studentLastName ?? ""
              }`}</h6>
            )}
            <p>
              {t("bed_number")} {index + 1}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default RoomHosteliteCard;
