import React, { useState } from "react";
import style from "../Navright.module.css";
import { LogoutQueryHandler } from "../../../../../Authentication/Logout/LogoutQuery";
import { useNavigate } from "react-router";
import { imageShowUrl } from "../../../../../services/BaseUrl";
import { useTranslation } from "react-i18next";
import Logout from "../../../../../Dashboard/InsDashboard/Modals/Logout";

const MODAL_STYLES = {
  position: "absolute",
  right: "0",
  top: "4.2vw",
  zIndex: 10,
};

const EDIT_DOT_STYLES = {
  position: "absolute",
  right: "2vw",
  top: "2.5vw",
  zIndex: 12,
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,

  zIndex: 10,
};

const OVERLAY_STYLESS = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,

  zIndex: 12,
};

function Edit({ open, onClose, handleClose, setEditModal }) {
  const { t } = useTranslation();
  if (!open) return null;
  return (
    <>
      <div onClick={onClose} style={OVERLAY_STYLESS} />
      <div
        style={EDIT_DOT_STYLES}
        className={style.editMenu}
        onClick={() => {
          onClose();
          handleClose();
          // setEditModal(true);
        }}
      >
        {t("edit_")}
      </div>
    </>
  );
}

function Item({ img, name, logout }) {
  return (
    <>
      {name === "Logout" ? (
        <div className={style.item} onClick={logout}>
          <img alt="img" src={img} />
          <h6>{name}</h6>
        </div>
      ) : (
        <div className={style.item}>
          <img alt="img" src={img} />
          <h6>{name}</h6>
        </div>
      )}
    </>
  );
}

function Menu({ manage, open, onClose, setEditModal }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [logout, setLogout] = useState(false);
  const changeLogout = () => {
    setLogout(false);
  };

  const LogoutHandler = () => {
    navigate("/");
    LogoutQueryHandler();
  };
  if (!open) return null;
  return (
    <>
      <div onClick={onClose} style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES} className={style.menu}>
        <div className={style.menutop}>
          <img
            className={style.menudots}
            onClick={() => setEdit(!edit)}
            alt="img"
            src="/images/menu-dots-icon.svg"
          />
          <div className={style.imgContainer}>
            <img
              alt="img"
              className={style.imageRound}
              src={
                manage?.affiliation_admin?.photoId !== "1"
                  ? `${imageShowUrl}/${manage?.affiliation_admin?.profilePhoto}`
                  : "/images/demo_users/user3.svg"
              }
            />
          </div>
          <div className={style.user1}>
            <h6>{manage?.affiliation_admin?.username}</h6>
            <p>{t("aff_admin")}</p>
          </div>
          <Edit
            open={edit}
            onClose={() => setEdit(false)}
            handleClose={onClose}
            setEditModal={setEditModal}
          />
        </div>

        <div className={style.bottom}>
          {/* <Item img="/images/admin/privacy-icon.svg" name="Privacy" />
        <Item img="/images/admin/setting-icon.svg" name="Setting" /> */}
          <Item
            img="/images/admin/logout-icon.svg"
            name="Logout"
            logout={() => setLogout(true)}
          />
        </div>
      </div>
      {logout && <Logout changeLogout={changeLogout} logout={logout} />}
    </>
  );
}

export default Menu;
