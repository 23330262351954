import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  baseUrl,
  // deleteMethod,
  //   patchMethod,
  // postMethod,
  session,
} from "../BaseUrl";

import {
  fetchStaffIdCard,
  extractStudentIDCard,
  fetchStudentExportData,
  fetchFinanceExportData,
} from "./ExportDataBuilder";

export const exportDataApi = createApi({
  reducerPath: "exportDataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      if (session()) {
        headers.set("authorization", session());
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchStaffIdCard: fetchStaffIdCard(builder),
    extractStudentIDCard: extractStudentIDCard(builder),
    fetchStudentExportDat: fetchStudentExportData(builder),
    fetchFinanceExportData: fetchFinanceExportData(builder),
  }),
});

export const {
  useFetchStaffIdCardQuery,
  useExtractStudentIDCardMutation,
  useFetchStudentExportDatQuery,
  useFetchFinanceExportDataQuery,
} = exportDataApi;
