import React from "react";
import style from "./InsTender.module.css";
import Navbar from "../SuperAdmin/MainContent/Navbar/Navbar";
import { useSelector } from "react-redux";
import Main from "./InsTenderMain/InsTenderMain";
import { useRenderManageDashboardQuery } from "../services/ManageAdmin/manageAdminAPI";
import secureLocalStorage from "react-secure-storage";

function InsTender() {
  const user_Id = secureLocalStorage.getItem("user");
  const author = useSelector((state) => state.authChange);
  var manageAdmin = `${author?.manageId ? author?.manageId : user_Id}`;
  const { data: manageQuery } = useRenderManageDashboardQuery(manageAdmin);
  return (
    <div className={style.bgContainer}>
      <div className={style.navbar}>
        <Navbar manage={manageQuery?.manage} />
      </div>
      <div className={style.editContainer}>
        <Main />
      </div>
    </div>
  );
}

export default InsTender;
