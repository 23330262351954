import React from "react";
import style from "./TransportManager.module.css";
import Navbar from "../SuperAdmin/MainContent/Navbar/Navbar";
import TransportMain from "./TransportMain/TransportMain";
import { useSelector } from "react-redux";
import { useRenderManageDashboardQuery } from "../services/ManageAdmin/manageAdminAPI";
import secureLocalStorage from "react-secure-storage";

function TransportManager() {
  const user_Id = secureLocalStorage.getItem("user");
  const author = useSelector((state) => state.authChange);
  var manageAdmin = `${author?.manageId ? author?.manageId : user_Id}`;
  const { data: manageQuery } = useRenderManageDashboardQuery(manageAdmin);
  return (
    <div className={style.bgContainer}>
      <div className={style.navbar}>
        <Navbar manage={manageQuery?.manage} />
      </div>
      <div className={style.editContainer}>
        <TransportMain />
      </div>
    </div>
  );
}
export default TransportManager;
