import React from "react";
import style from "../HostelWardens/HostelWardens.module.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetOngoingApplicationList } from "../../../hooks/member_tab/hostel-api";
import { useEffect } from "react";
import QvipleLoading from "../../../Loader/QvipleLoading";
import BorderBottom from "../../../SuperAdmin/MainContent/Students/StudentProfile/BorderBottom";

function Item({
  data,
  carryParentState,
  accessRole,
  accessApplication,
  setOpenApplicationMenu,
}) {
  const onMenu = (e) => {
    e.preventDefault();
    setOpenApplicationMenu(data);
  };
  return (
    <>
      <div className={style.item}>
        <div className={style.subItem}>
          <img alt="Ongoing App" src="/images/admision-application-icon.svg" />
          <div className={style.text}>
            <h6>{data?.applicationName}</h6>
            <p
              style={{
                marginBottom: "0",
              }}
            >
              {data?.applicationUnit?.hostel_unit_name ?? ""}
            </p>
          </div>
        </div>
        <div className={style.text}>
          <p
            style={{
              marginRight: "2rem",
              marginBottom: "0",
            }}
          >
            {data?.applicationEndDate}
          </p>
        </div>
        {/* <img
          src="/images/three-24-dot-icon.svg"
          alt="menu icon"
          onClick={onMenu}
          className={style.menu_icon}
          title="Menu"
        /> */}
      </div>
    </>
  );
}

function HostelApplications({ hid }) {
  const { t } = useTranslation();
  const [state, setState] = useState(true);
  const [page, setPage] = useState(1);
  const [ref, inView] = useInView();
  const [ongoing, setOngoing] = useState([]);
  const [showingDataLoading, setShowingDataLoading] = useState(true);
  const [refetchStatus, setRefetchStatus] = useState(false);
  const { getOngoingApplicationList, getOngoingApplicationListRefetch } =
    useGetOngoingApplicationList({
      data: {
        hid: hid,
        page: page,
        limit: 10,
      },
      skip: !hid,
    });

  useEffect(() => {
    if (inView && state) setPage((page) => page + 1);
  }, [inView, state]);

  useEffect(() => {
    if (hid) {
      setShowingDataLoading(true);
      getOngoingApplicationListRefetch();
    }
  }, [hid, page, getOngoingApplicationListRefetch]);

  useEffect(() => {
    if (refetchStatus) {
      setOngoing(getOngoingApplicationList?.ongoing);
      setShowingDataLoading(false);
      setRefetchStatus(false);
    } else {
      if (getOngoingApplicationList?.ongoing) {
        setOngoing((prevState) =>
          [
            ...new Set(
              [...prevState, ...getOngoingApplicationList?.ongoing]?.map(
                JSON.stringify
              )
            ),
          ]?.map(JSON.parse)
        );
        setShowingDataLoading(false);
      } else if (getOngoingApplicationList?.ongoing?.length === 0)
        setShowingDataLoading(false);
      else {
      }
    }

    if (getOngoingApplicationList?.ongoing?.length === 10) setState(true);
    else setState(false);
  }, [getOngoingApplicationList?.ongoing]);
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div className={style.applicationTop}>{t("admission_renewal")}</div>
      <BorderBottom
        customStyle={{
          width: "100%",
          marginBottom: "0.5rem",
        }}
      />
      <div className={style.remainingFeesBottom}>
        <div className={style.itemsContainer}>
          {ongoing?.map((ele, index) =>
            ongoing?.length === index + 1 ? (
              <div ref={ref} key={index}>
                <Item data={ele} />
              </div>
            ) : (
              <Item data={ele} key={index} />
            )
          )}
          {showingDataLoading && <QvipleLoading />}
        </div>
      </div>
    </div>
  );
}

export default HostelApplications;
