import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useGetHostelUnitDetail } from "../../../../hooks/member_tab/hostel-api";
import VehicleHeader from "../../../../TransportManager/TransportMain/Vehicles/InstituteVehcileDetail/VehicleHeader";
import style from "./HostelUnitProfile.module.css";
import Tabs from "./Tabs/Tabs";
import Hostelites from "../../Hostelites/Hostelites";
import HostelUnitRooms from "./HostelUnitRooms/HostelUnitRooms";

function HostelUnitProfile({ hid, carryParentState }) {
  const { t } = useTranslation();
  const getQuery = useLocation();
  const [photoURL, setPhotoURL] = useState("");
  const [file, setFile] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [upload, setUpload] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [disabled, setDiasbled] = useState(false);

  const { getHostelUnitDetail, getHostelUnitDetailRefetch } =
    useGetHostelUnitDetail({
      huid: getQuery?.state?.huid,
      skip: !getQuery?.state?.huid,
    });

  return (
    <>
      <VehicleHeader hunit={getHostelUnitDetail?.one_unit} status="hostel" />
      <div className={style.mainContainer}>
        <Tabs activeIndex={activeIndex} setActiveIndex={setActiveIndex} />

        {activeIndex === 0 && (
          <div className={style.hosteliteContainer}>
            <Hostelites hid={hid} filter_by={getQuery?.state?.huid} />
          </div>
        )}

        {activeIndex === 1 && (
          <div className={style.hosteliteContainer}>
            <HostelUnitRooms
              hid={hid}
              huid={getQuery?.state?.huid}
              carryParentState={carryParentState}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default HostelUnitProfile;
