import React from "react";
import Overlay from "../Wrapper/Overlay";
import SearchModalWrapper from "../Wrapper/SearchModalWrapper";

const PopupWrapper = ({ onClose, children }) => {
  return (
    <>
      <SearchModalWrapper onClose={onClose} />
      <Overlay>{children}</Overlay>
    </>
  );
};

export default PopupWrapper;
