import React from "react";
import CommonSideCard from "../Sidebar/CommonSideCard";
import Goback from "../Sidebar/Goback";
import CommonSidebarBackground from "../Sidebar/CommonSidebarBackground";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

function EventsSidebar({ username, eventId, event }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "sticky",
        top: "77px",
        height: "fit-content",
      }}
    >
      <Goback
        linkType={false}
        linkUrl={`/q/${username}/feed`}
        changeIndex={() => navigate(-1)}
      />
      <CommonSidebarBackground
        dynamicImage="/images/profileAndCover/eventAdmin-cover.png"
        edit={false}
        // onEditAction={onOpenMenu}
      />

      <CommonSideCard
        cardAsHead={true}
        heading={` ${event?.event_head?.staffFirstName || ""} ${
          event?.event_head?.staffMiddleName
            ? event?.event_head?.staffMiddleName
            : ""
        } ${event?.event_head?.staffLastName || ""}`}
        description={t("event_manager")}
        customStyleCard={{
          borderRadius: "1rem 1rem 0 0",
          border: "none",
          marginTop: "-2.49vw",
        }}
      />
      <CommonSideCard
        cardAsHead={false}
        heading={t("upcoming_events")}
        description={t("upcoming_events_count")}
        count={event?.event_count || 0}
      />
      <CommonSideCard
        cardAsHead={false}
        heading={t("upcoming_seminars")}
        description={t("upcoming_seminars_count")}
        count={event?.seminar_count || 0}
      />
      <CommonSideCard
        cardAsHead={false}
        heading={t("electionss")}
        description={t("elections_count")}
        count={event?.election_count || 0}
      />
      <CommonSideCard
        cardAsHead={false}
        heading={t("competitionss")}
        description={t("competitons_count")}
        count={event?.participate_count || 0}
      />
    </div>
  );
}

export default EventsSidebar;
