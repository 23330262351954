import React, { useEffect, useState } from "react";
import style from "../../HostelUnitProfile.module.css";
import VehicleHeader from "../../../../../../TransportManager/TransportMain/Vehicles/InstituteVehcileDetail/VehicleHeader";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useGetRoomDetailHostel } from "../../../../../../hooks/member_tab/hostel-api";
import RoomHosteliteCard from "./RoomHosteliteCard";

function HostelRoomProfile() {
  const { t } = useTranslation();
  const getQuery = useLocation();
  const [vacantList, setVacantList] = useState([]);
  const { getRoomDetailHostel, getRoomDetailHostelRefetch } =
    useGetRoomDetailHostel({
      hrid: getQuery.state?.hrid,
      skip: !getQuery.state?.hrid,
    });

  useEffect(() => {
    if (
      getRoomDetailHostel?.one_room?.vacant_count ||
      getRoomDetailHostel?.one_room?.beds
    ) {
      // setVacantList
      let arr = [...getRoomDetailHostel?.one_room?.beds];
      for (let i = 0; i < getRoomDetailHostel?.one_room?.vacant_count; i++)
        arr.push({
          bedVacant: true,
        });

      setVacantList(arr);
    }
  }, [
    getRoomDetailHostel?.one_room?.vacant_count,
    getRoomDetailHostel?.one_room?.beds,
  ]);

  return (
    <>
      <VehicleHeader room={getRoomDetailHostel?.one_room} status="room" />
      <div className={style.mainContainer}>
        <div className={style.unit_room_container_box}>
          <div className={style.unit_room_container_box_inner}>
            {vacantList?.map((hostelite, index) => (
              <RoomHosteliteCard
                key={index}
                hostelite={hostelite}
                vacant={hostelite?.bedVacant ? true : false}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default HostelRoomProfile;
