import secureLocalStorage from "react-secure-storage";

const postUrl = "/api/v1";
var id = secureLocalStorage.getItem("user");

// -------------------Switch User Account--------------------------------------------

export const switchUserAccount = (builder) => {
  return builder.query({
    query: () => `${postUrl}/user/${id}/staff/student/role`,
  });
};
