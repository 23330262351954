const postUrl = "/api/v1";

export const fetchStudentInsInfo = (builder) => {
  return builder.query({
    query: (id) => `/ins/${id}/profile`,
  });
};

export const fetchGRStudent = (builder) => {
  return builder.mutation({
    query: (grData) => ({
      url: `/extra/bonafide/certificate/${grData.number}/ins/${grData?.id}`,
      method: "POST",
      body: grData,
    }),
  });
};

export const fetchStudentLeaving = (builder) => {
  return builder.mutation({
    query: (grData) => ({
      url: `/extra/leaving/certificate/${grData.number}/ins/${grData?.id}`,
      method: "POST",
      body: grData,
    }),
  });
};



export const addEditableQuery = (builder) => {
  return builder.mutation({
    query: (text) => ({
      url: `/ins/${text?.id}/certificate/editable`,
      method: "PATCH",
      body: text?.e_data,
    }),
  });
};

export const fetchEditableQuery = (builder) => {
  return builder.query({
    query: (id) => `/ins/${id}/certificate/editable/detail`,
  });
};

