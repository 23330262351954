import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { qvipleApi } from "../../services/qvipleAPI";
import { dashboardApi } from "../../services/Dashboard/dashboardApi";
import { superAdminAPI } from "../../services/superAdminAPI";
import { manageAdminAPI } from "../../services/ManageAdmin/manageAdminAPI";
import { instituteStaffStudentApi } from "../../services/InstituteStaffStudent/instituteStaffStudentApi";
import { chatAPI } from "../../services/chatAPI";

import { financeAPI } from "../../services/financeAPI";
import { paymentConfigureAPI } from "../../services/RazorPay/paymentConfigure";
import { studenttApi } from "../../services/certificateAPI";
import { joinApi } from "../../services/joining/joinApi";
import { sportsAndArtsApi } from "../../services/sports_and_arts/sportsAndArtsApi";
import { switchAccountApi } from "../../services/switchAccount/switchAccountApi";
import { CLTApi } from "../../services/CLTApi";
import { instituteDepartmentApi } from "../../services/instituteDepartmentApi";
import { postApi } from "../../services/postApi";
import { imageApi } from "../../services/imageApi";
import { searchApi } from "../../services/searchApi";
import { attendanceApi } from "../../services/attendanceApi";
import { settingApi } from "../../services/Setting/settingApi";
import { departmentApi } from "../../services/staff_department/departmentApi";
import { admissionNewApi } from "../../services/admission/admissionNewApi";
import { classApi } from "../../services/staff_department/classApi";
import { subjectApi } from "../../services/staff_department/subjectApi";
import { plateformApi } from "../../services/plateform/plateformApi";
import { admissionAPI } from "../../services/admission/admissionAPI";
import { studentMembersTabApi } from "../../services/student_members_ab/studentMembersTabApi";
import { exportDataApi } from "../../services/ExportData/ExportDataApi";
import { insDashboardApi } from "../../services/Dashboard/InsDashboard/InsDashboardApi";
import { libraryApi } from "../../services/library/libraryApi";
import { careerApi } from "../../services/career/careerApi";
import { eventApi } from "../../services/event/eventApi";
import { transportApi } from "../../services/transport/transportApi";
import { hostelApi } from "../../services/hostel/hostelApi";

import idChangeReducer from "./department-slice";
import authChangeReducer from "./authenticate-slice";
import postChangeReducer from "./post-slice";
import chatChangeReducer from "./chat-slice";
import financeChangeReducer from "./finance-slice";
import profileChangeReducer from "./profile-slice";
import adminChangeReducer from "./superAdmin-slice";
import admissionChangeReducer from "./admission-slice";
import sportsArtChangeReducer from "./sports-and-arts-slice";
import libraryChangeReducer from "./library-slice";
import careerChangeReducer from "./career-slice";
import tenderChangeReducer from "./tender-slice";

export const store = configureStore({
  reducer: {
    [studenttApi.reducerPath]: studenttApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [instituteStaffStudentApi.reducerPath]: instituteStaffStudentApi.reducer,
    [qvipleApi.reducerPath]: qvipleApi.reducer,
    [superAdminAPI.reducerPath]: superAdminAPI.reducer,
    [manageAdminAPI.reducerPath]: manageAdminAPI.reducer,
    [chatAPI.reducerPath]: chatAPI.reducer,
    [financeAPI.reducerPath]: financeAPI.reducer,
    [paymentConfigureAPI.reducerPath]: paymentConfigureAPI.reducer,
    [CLTApi.reducerPath]: CLTApi.reducer,
    [instituteDepartmentApi.reducerPath]: instituteDepartmentApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [imageApi.reducerPath]: imageApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [attendanceApi.reducerPath]: attendanceApi.reducer,
    [settingApi.reducerPath]: settingApi.reducer,
    [joinApi.reducerPath]: joinApi.reducer,
    [sportsAndArtsApi.reducerPath]: sportsAndArtsApi.reducer,
    [switchAccountApi.reducerPath]: switchAccountApi.reducer,
    [departmentApi.reducerPath]: departmentApi.reducer,
    [classApi.reducerPath]: classApi.reducer,
    [subjectApi.reducerPath]: subjectApi.reducer,
    [plateformApi.reducerPath]: plateformApi.reducer,
    [admissionAPI.reducerPath]: admissionAPI.reducer,
    [studentMembersTabApi.reducerPath]: studentMembersTabApi.reducer,
    [admissionNewApi.reducerPath]: admissionNewApi.reducer,
    [exportDataApi.reducerPath]: exportDataApi.reducer,
    [insDashboardApi.reducerPath]: insDashboardApi.reducer,
    [libraryApi.reducerPath]: libraryApi.reducer,
    [careerApi.reducerPath]: careerApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [transportApi.reducerPath]: transportApi.reducer,
    [hostelApi.reducerPath]: hostelApi.reducer,
    idChange: idChangeReducer,
    authChange: authChangeReducer,
    postChange: postChangeReducer,
    chatChange: chatChangeReducer,
    financeChange: financeChangeReducer,
    profileChange: profileChangeReducer,
    adminChange: adminChangeReducer,
    admissionChange: admissionChangeReducer,
    sportsArtChange: sportsArtChangeReducer,
    libraryChange: libraryChangeReducer,
    careerChange: careerChangeReducer,
    tenderChange: tenderChangeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      qvipleApi.middleware,
      dashboardApi.middleware,
      instituteDepartmentApi.middleware,
      instituteStaffStudentApi.middleware,
      postApi.middleware,
      imageApi.middleware,
      searchApi.middleware,
      attendanceApi.middleware,
      settingApi.middleware,
      superAdminAPI.middleware,
      manageAdminAPI.middleware,
      chatAPI.middleware,
      financeAPI.middleware,
      paymentConfigureAPI.middleware,
      CLTApi.middleware,
      exportDataApi.middleware,
      insDashboardApi.middleware,
      joinApi.middleware,
      sportsAndArtsApi.middleware,
      switchAccountApi.middleware,
      departmentApi.middleware,
      classApi.middleware,
      subjectApi.middleware,
      plateformApi.middleware,
      admissionAPI.middleware,
      admissionNewApi.middleware,
      studentMembersTabApi.middleware,
      libraryApi.middleware,
      careerApi.middleware,
      eventApi.middleware,
      transportApi.middleware,
      hostelApi.middleware
    ),
});

setupListeners(store.dispatch);
