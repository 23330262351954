import secureLocalStorage from "react-secure-storage";
export const baseUrl = "https://qviple.com";

export const postMethod = "POST";
export const patchMethod = "PATCH";
export const putMethod = "PUT";
export const deleteMethod = "DELETE";

export const session = () => {
  var token = secureLocalStorage.getItem("token");
  setTimeout(() => {
    if (token) {
      return "";
    } else {
      token = secureLocalStorage.getItem("token");
    }
  }, [100]);
  return token;
};

export const imageShowUrl1 = "https://duoxxvrxc30wv.cloudfront.net";
export const imageShowUrl = "https://duoxxvrxc30wv.cloudfront.net";

export const fileShowUrl = "https://duoxxvrxc30wv.cloudfront.net";
