import React from "react";
import style from "./InsEvents.module.css";
import EventsSidebar from "./EventsSidebar";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useOneInstituteDashboard } from "../hooks/dashboard/dashboard-api";
import { useGeteventDeptDetail } from "../hooks/member_tab/event-api";
import { useRenderManageDashboardQuery } from "../services/ManageAdmin/manageAdminAPI";
import Navbar from "../SuperAdmin/MainContent/Navbar/Navbar";
import CommonTab from "../Sidebar/CommonTab";
import { useTranslation } from "react-i18next";
import CustomSuspense from "../Custom/SuspenseLoading/CustomSuspense";
import InsEventMenu from "./InsEventMenu";
import Events from "./Events/Events";
import Seminars from "./Seminars/Seminars";
import InsDepartmentList from "./InsDepartmentList/InsDepartmentList";
import Elections from "./Elections/Elections";
import ParticipateEvents from "./ParticipateEvents/ParticipateEvents";
import secureLocalStorage from "react-secure-storage";

function InsEvents() {
  const params = useParams();
  const { t } = useTranslation();
  const ids = useSelector((state) => state.idChange);
  const [activeIndex, setActiveIndex] = useState(0);
  const [status, setStatus] = useState("");
  const user_Id = secureLocalStorage.getItem("user");
  const author = useSelector((state) => state.authChange);
  var manageAdmin = `${author?.manageId ? author?.manageId : user_Id}`;
  const { data: manageQuery } = useRenderManageDashboardQuery(manageAdmin);
  const { oneInstituteDashboard, oneInstituteDashboardRefetch } =
    useOneInstituteDashboard({
      id: ids?.activeIns,
      skip: !ids?.activeIns,
    });

  const { getEventDeptDetail, getEventDeptDetailRefetch } =
    useGeteventDeptDetail({
      eid: oneInstituteDashboard?.institute?.eventManagerDepart[0],
      skip: !oneInstituteDashboard?.institute?.eventManagerDepart[0],
    });

  // console.info(getEventDeptDetail);

  return (
    <div className={style.bgContainer}>
      <div className={style.navbar}>
        <Navbar manage={manageQuery?.manage} />
      </div>
      <div className={style.editContainer}>
        <div className={style.main}>
          <div className={style.fineside}>
            <EventsSidebar
              username={params.username}
              eventId={oneInstituteDashboard?.institute?.eventManagerDepart[0]}
              event={getEventDeptDetail?.manager}
            />
          </div>

          <div
            className={
              ![0, 1].includes(activeIndex)
                ? style.finance_container_other
                : style.finance_container
            }
          >
            {(activeIndex === 0 || activeIndex === 1) && (
              <div className={style.finance_tab_tabs}>
                <CommonTab
                  tabLevel={t("menu")}
                  onTabAction={() => setActiveIndex(0)}
                  customStyleContainer={{
                    width: "50%",
                    marginRight: "2px",
                    borderTopLeftRadius: "6px",
                  }}
                  customStyleLevel={{ borderTopLeftRadius: "6px" }}
                  tabValue={0}
                  currentValue={activeIndex}
                />
                <CommonTab
                  tabLevel={t("departments")}
                  onTabAction={() => setActiveIndex(1)}
                  customStyleContainer={{
                    width: "50%",
                    borderTopRightRadius: "6px",
                  }}
                  customStyleLevel={{ borderTopLeftRadius: "6px" }}
                  tabValue={1}
                  currentValue={activeIndex}
                />
              </div>
            )}
            {/* {![0, 1].includes(activeIndex) && (
              <CustomSuspense>
                <div className={style.finance_tab_tabs}>
                  <InsEventTabs
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                  />
                </div>
              </CustomSuspense>
            )} */}

            {activeIndex === 0 && (
              <InsEventMenu setActiveIndex={setActiveIndex} />
            )}
            {activeIndex === 1 && (
              <CustomSuspense>
                <InsDepartmentList insId={ids?.activeIns} />
              </CustomSuspense>
            )}
            <CustomSuspense>
              {activeIndex === 2 && (
                <Events
                  eventId={
                    oneInstituteDashboard?.institute?.eventManagerDepart[0]
                  }
                  setActiveIndex={setActiveIndex}
                  eventCount={getEventDeptDetail?.manager?.event_count}
                />
              )}
              {activeIndex === 3 && (
                <Seminars
                  eventId={
                    oneInstituteDashboard?.institute?.eventManagerDepart[0]
                  }
                  setActiveIndex={setActiveIndex}
                  eventCount={getEventDeptDetail?.manager?.seminar_count}
                />
              )}
              {activeIndex === 4 && (
                <Elections
                  eventDeptId={
                    oneInstituteDashboard?.institute?.eventManagerDepart[0]
                  }
                  setActiveIndex={setActiveIndex}
                />
              )}
              {activeIndex === 5 && (
                <ParticipateEvents
                  eventDeptId={
                    oneInstituteDashboard?.institute?.eventManagerDepart[0]
                  }
                  setActiveIndex={setActiveIndex}
                />
              )}
              {/* {activeIndex === 6 && (
            <div style={{ marginTop: "1vw" }}>
              <Repayment insId={oneInstituteDashboard?.institute?._id} />
            </div>
          )} */}
              {/* {activeIndex === 7 && (
            <History insId={oneInstituteDashboard?.institute?._id} />
          )} */}
            </CustomSuspense>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsEvents;
